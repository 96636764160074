import { fetchHeaders } from "./fetchHeaders"
import { BACKEND_FAECHER_ALL, BACKEND_FAECHER_ADDSUBJECT } from "../constants/routes"

export const getFaecher = (setAll) => {
  return fetch(BACKEND_FAECHER_ALL, {
    method: "GET",
    headers: fetchHeaders,
  })
  .then(response => {
    return response.json()
  })
  .then(data => {
    let result = Object.keys(data).map((key) => data[key]) // converts the data object to an array
    setAll(result)
  })
}

export const addSubject = (data) => {
  return fetch(BACKEND_FAECHER_ADDSUBJECT, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(data)
  })
  .then(response => {
    return response.json()
  })
}