import { fetchHeaders } from "./fetchHeaders"
import {
  BACKEND_SCHUELER_FORGOT,
  BACKEND_SCHUELER_NEWPW,
  BACKEND_SCHUELER_LOGIN,
  BACKEND_SCHUELER_REGISTER,
  BACKEND_SCHUELER_VALIDATECODE,
} from "../constants/routes"

export const confirmCode = (userdata) => {
  return fetch(BACKEND_SCHUELER_VALIDATECODE, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(userdata),
  }).then((data) => data.json())
}

export const sendNewPW = (emailTo) => {
  return fetch(BACKEND_SCHUELER_FORGOT, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(emailTo),
  }).then((data) => data.json())
}

export const saveNewPW = (newP) => {
  return fetch(BACKEND_SCHUELER_NEWPW, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(newP),
  }).then((data) => data.json())
}

export const userLogin = (userdata) => {
  return fetch(BACKEND_SCHUELER_LOGIN, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(userdata),
  }).then((data) => data.json())
}

export const userRegister = (userdata) => {
  return fetch(BACKEND_SCHUELER_REGISTER, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(userdata),
  }).then((data) => data.json())
}
