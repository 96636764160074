import React, { useContext } from "react"
import { Box, Typography, Link, Paper, Divider, Grid } from "@mui/material"
import LicenceContext from "../../context/LicenceContext"
import { licencesList, modules } from "../Start/Licences/Licences"

const Lizenzen = () => {
  const { licenceData } = useContext(LicenceContext)
  const parsed = JSON.parse(licenceData?.lizenz)
  const { runtime } = parsed.configuration

  const { datum, bezahlt } = licenceData?.lizenzDaten

  const boughtAt = new Date(datum)
  const boughtAtInMs = boughtAt.getTime()
  const validThru = new Date(boughtAtInMs + runtime)

  const formattedBoughtAt = boughtAt.getDate() + "." + (boughtAt.getMonth() + 1) + "." + boughtAt.getFullYear()
  const formattedValidThru = validThru.getDate() + "." + (validThru.getMonth() + 1) + "." + validThru.getFullYear()

  const lizenzDaten = licenceData?.lizenzDaten
  const modulDaten = licenceData?.modulDaten

  const suggestedLicence =
    lizenzDaten.name === "Komplett" ? licencesList.filter((l) => l.title === "Progressiv")[0] : licencesList.filter((l) => l.title === "Komplett")[0]

  let suggestedModules = []
  switch (modulDaten.length) {
    case 1:
      suggestedModules = modules.filter((m) => m.title !== modulDaten[0].name)
      break
    case 2:
      suggestedModules = modules.filter((m) => m.title === "Personalisiert")
      break
    default:
      suggestedModules = modules
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: "1vw",
        left: "12vw",
        borderRadius: "38px",
        backgroundColor: "white",
        height: "calc(100vh - 2vw)",
        width: "87vw",
        p: "1vw",
        overflow: "auto",
        msOverflowStyle: "-ms-autohiding-scrollbar" /* IE and Edge */, // ????
        scrollbarWidth: "none" /* Firefox */,
      }}>
      <Typography variant="h5" align="left" sx={{ color: "bmsecondary.main", ml: "1vw", pt: "1vw" }} gutterBottom>
        Lizenzen und Module
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={5.9}>
          <Typography variant="h5" sx={{ ml: "1vw", my: "2vh" }} gutterBottom>
            Angelegt
          </Typography>
          <Typography variant="h6" sx={{ ml: "1vw" }} gutterBottom>
            Lizenz
          </Typography>
          <Paper
            elevation={0}
            sx={{
              my: 2,
              p: 2,
              bgcolor: "bmprimary.main",
              width: "60%",
              ml: "1vw",
            }}>
            <Typography variant="h6">{lizenzDaten.name}</Typography>
            <Typography variant="subtitle1">{lizenzDaten.beschreibung}</Typography>
            <Typography variant="subtitle1">Angelegt am: {formattedBoughtAt}</Typography>
            <Typography variant="subtitle1">Gültig bis: {formattedValidThru}</Typography>
            <Typography variant="subtitle1">Bezahlt: {bezahlt ? "Ja" : "Nein"}</Typography>
          </Paper>
          <Typography variant="h6" sx={{ ml: "1vw" }} gutterBottom>
            Module
          </Typography>
          {modulDaten.length > 0 &&
            modulDaten.map((m) => (
              <Paper
                key={m.name}
                elevation={0}
                sx={{
                  my: 2,
                  p: 2,
                  bgcolor: "grey.200",
                  width: "60%",
                  ml: "1vw",
                }}>
                <Typography variant="h6">{m.name}</Typography>
                <Typography variant="subtitle1">{m.beschreibung}</Typography>
              </Paper>
            ))}
        </Grid>
        <Grid item xs={0.2}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5.9}>
          <Typography variant="h5" sx={{ my: "2vh" }}>
            Vorschläge
          </Typography>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://bookay-buecher.de/start?page=lizenzen"
            sx={{ fontWeight: "bold", color: "text.primary", textDecoration: "none", ml: "1vw", mt: "5vw" }}>
            Hier klicken, um weitere Module anzulegen
          </Link>
          <Typography variant="h6" sx={{ ml: "1vw" }} gutterBottom>
            Lizenz
          </Typography>
          <Paper
            elevation={0}
            sx={{
              my: 2,
              p: 2,
              bgcolor: "bmprimary.main",
              width: "60%",
              ml: "1vw",
            }}>
            <Typography variant="h6">{suggestedLicence.title}</Typography>
            <Typography variant="subtitle1">{suggestedLicence.description}</Typography>
          </Paper>
          <Typography variant="h6" sx={{ ml: "1vw" }} gutterBottom>
            Module
          </Typography>
          {suggestedModules.length > 0 &&
            suggestedModules.map((m) => (
              <Paper
                key={m.title}
                elevation={0}
                sx={{
                  my: 2,
                  p: 2,
                  bgcolor: "grey.200",
                  width: "60%",
                  ml: "1vw",
                }}>
                <Typography variant="h6">{m.title}</Typography>
                <Typography variant="subtitle1">{m.description}</Typography>
              </Paper>
            ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Lizenzen
