import React from "react"
import { Grid, Typography, Paper, Link } from "@mui/material"

import MainFeaturedPost from "../MainFeaturedPost"
import Main from "../Main"
import Sidebar from "../Sidebar"

import image from "../img/schueler2.png"
import verwaltungimg from "../img/verwaltung.png"
import buyimg from "../img/buy.png"
import chatimg from "../img/chat.png"

const mainFeaturedPost = {
  title: "Die Bookay Web App",
  description: "Digitale Schulbuchverwaltung",
  image: image,
  imageText: "Main Image",
  linkText: "Für Schulkoordinatoren und Schüler",
}

const posts = [
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      // backgroundImage: `url(${image})`,
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Aufbau der App und Funktionalität
    </Typography>
    <Link target="_blank" rel="noopener noreferrer" href="http://demo.bookay-buecher.de">
      Hier geht es zur App-Demo
    </Link>
    <Typography paragraph>
      Die Web-App gliedert sich in zwei Bereiche: <br /> • die Bücherverwaltung für die Schulverwaltung und <br /> • die Schüler-Plattform für die
      Schüler
    </Typography>
    <Typography paragraph>In der Bücherverwaltung werden die Bücherlisten erstellt.</Typography>
    <Typography paragraph>
      Auf der Schüler-Plattform sehen die Schüler die Bücherlisten und können diese Bücher zum Kauf bzw. Verkauf einstellen. Danach wird automatisch
      ein Partner gesucht, der das gleiche Buch verkaufen bzw. kaufen möchte. Beide Schüler erhalten eine Benachrichtigung und können über unseren
      Chat die Übergabe des Buches besprechen.
    </Typography>
    <Typography paragraph>
      Außerdem sieht man in der persönlichen Übersicht eine Zusammenfassung der bereits gekauften bzw. verkauften Bücher.
    </Typography>
  </Paper>,
  <Grid container sx={{ my: 2, p: 2, bgcolor: "grey.200" }}>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom paragraph>
        Beispiel: Erstellen einer Bücherliste
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Paper
        elevation={0}
        sx={{
          bgcolor: "grey.200",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${verwaltungimg})`,
          height: "30vh",
        }}>
        <Typography sx={{ visibility: "hidden" }}>-</Typography>
      </Paper>
    </Grid>
  </Grid>,
  <Grid container sx={{ my: 2, p: 2, bgcolor: "grey.200" }}>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom paragraph>
        Beispiel: Kauf eines Buches
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Paper
        elevation={0}
        sx={{
          bgcolor: "grey.200",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${buyimg})`,
          height: "30vh",
        }}>
        <Typography sx={{ visibility: "hidden" }}>-</Typography>
      </Paper>
    </Grid>
  </Grid>,
  <Grid container sx={{ my: 2, p: 2, bgcolor: "grey.200" }}>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom paragraph>
        Beispiel: Schülerchat
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Paper
        elevation={0}
        sx={{
          bgcolor: "grey.200",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${chatimg})`,
          height: "30vh",
        }}>
        <Typography sx={{ visibility: "hidden" }}>-</Typography>
      </Paper>
    </Grid>
  </Grid>,
]

const AppInfo = ({ setCurrentPage }) => {
  return (
    <>
      <MainFeaturedPost post={mainFeaturedPost} align="right"/>
      <Grid container spacing={5} sx={{ mt: 3 }}>
        <Main title="Inhalt der App" posts={posts} />
        <Sidebar setCurrentPage={setCurrentPage} />
      </Grid>
    </>
  )
}

export default AppInfo
