import React from "react"
import { useState } from "react"
import UserContext from "./UserContext"

const initialUser = () => {
  const u = localStorage.getItem("user")
  return (u && u !== "undefined") ? JSON.parse(u) : null
}

const UserProvider = (props) => {
  const [userData, setUserData] = useState(initialUser())

  const setUserDataExternal = (data) => {
    localStorage.setItem("user", JSON.stringify(data))
    setUserData(data)
  }

  return <UserContext.Provider value={{ userData, setUserDataExternal }}>{props.children}</UserContext.Provider>
}

export default UserProvider
