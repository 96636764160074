import React from "react"
import { Grid, Typography, List, ListItem, ListItemText } from "@mui/material"
import { licencesList, modules } from "../Licences/Licences"

const getLicence = (initLicence) => {
  const result = Object.keys(initLicence).map((key) => [key, initLicence[key]])
  const initial = result.filter((r) => r[1])
  const chosenLicenceReady = licencesList.filter((l) => l.id === initial[0][0])
  return chosenLicenceReady[0]
}

const getModules = (initModule) => {
  const result2 = Object.keys(initModule).map((key) => [key, initModule[key]])
  const initial2 = result2.filter((r) => r[1])
  const ready = initial2.map((m) => m[0])
  return modules.filter((l) => ready.includes(l.id))
}

const calcGesamtPreis = (licence, modules) => {
  const { price: licencePrice } = licence
  const modulePrices = modules.map((m) => m.price || 0)
  const modulePrice = modulePrices.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
  return parseFloat(licencePrice) + modulePrice
}

const Review = ({ schuldatenInfo, lizenzenInfo }) => {
  const { lizenzen: initLicence, module: initModule } = lizenzenInfo
  const chosenLicenceInfo = getLicence(initLicence)
  const chosenModulesInfo = getModules(initModule)
  const gesamtPreis = calcGesamtPreis(chosenLicenceInfo, chosenModulesInfo)

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Übersicht
      </Typography>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Lizenzen" />
        </ListItem>
        <ListItem sx={{ py: 1, pl: 2, pr: 0 }}>
          <ListItemText primary={chosenLicenceInfo.title} secondary={chosenLicenceInfo.shortDescription} />
          <Typography variant="body2">{chosenLicenceInfo.price ? `${chosenLicenceInfo.price} €` : "Nach Absprache"}</Typography>
        </ListItem>

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Module" />
        </ListItem>
        {chosenModulesInfo.map((m, index) => (
          <ListItem key={index} sx={{ py: 1, pl: 2, pr: 0 }}>
            <ListItemText primary={m.title} secondary={m.shortDescription} />
            <Typography variant="body2">{m.price ? `${m.price} €` : "Nach Absprache"}</Typography>
          </ListItem>
        ))}

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Gesamt" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {gesamtPreis && gesamtPreis % 10 !== 1  ? `${gesamtPreis}€` : "Nach Absprache"}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ wordWrap: "break-word" }}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Schuldaten
          </Typography>
          <Typography gutterBottom>{schuldatenInfo.schulname}</Typography>
          <Typography gutterBottom>{schuldatenInfo.kontakt || ""}</Typography>
          <Typography gutterBottom>{schuldatenInfo.email}</Typography>
          <Typography gutterBottom>{schuldatenInfo.bundesland}</Typography>
          <Typography gutterBottom>{schuldatenInfo.stadt}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Verarbeitung
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom>Email an angegebene Adresse</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>
                Nach der unverbindlichen Anfrage erhalten Sie weitere Informationen bezüglich der ausgewählten Lizenzen und Module.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" align="right" gutterBottom>
            Mit dem Klicken auf "Unverbindlich anfragen" stimmen Sie den AGB zu.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Review
