import React from "react"
import { Grid, Typography, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import { alleBundeslaender } from "../../../constants/bundeslaender"

const Schuldaten = ({ userData, schuldatenInfo, setSchuldatenInfo, setValidSchoolData }) => {
  const handleChange = (val, id) => {
    const newData = { ...schuldatenInfo }
    newData[id] = val
    setSchuldatenInfo(newData)

    const { schulname, email, bundesland, stadt } = newData
    if (schulname !== "" && email !== "" && bundesland !== "" && stadt !== "") {
      setValidSchoolData(true)
    } else {
      setValidSchoolData(false)
    }
  }

  if (!!userData) {
    setValidSchoolData(true)
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Schuldaten
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Wenn Sie bereits einen Account haben, loggen Sie sich bitte ein, um weitere Lizenzen und Module anzulegen.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            disabled={!!userData}
            value={userData?.Schulname || schuldatenInfo.schulname || ""}
            required
            id="schulname"
            name="schulname"
            label="Schulname"
            fullWidth
            variant="standard"
            onChange={(e) => handleChange(e.target.value, "schulname")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!!userData}
            value={userData?.Email || schuldatenInfo.email || ""}
            required
            id="email"
            name="email"
            label="Emailadresse"
            fullWidth
            variant="standard"
            onChange={(e) => handleChange(e.target.value, "email")}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120, width: "100%", m: 0 }}>
            <InputLabel id="demo-simple-select-standard-label">Bundesland *</InputLabel>
            <Select
              disabled={!!userData}
              required
              value={userData?.Bundesland || schuldatenInfo.bundesland || ""}
              id="bundesland"
              name="bundesland"
              label="Bundesland *"
              variant="standard"
              onChange={(e) => handleChange(e.target.value, "bundesland")}>
              {alleBundeslaender.map((b, index) => (
                <MenuItem key={index} value={index + 1}>
                  {b}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!!userData}
            value={userData?.Stadt || schuldatenInfo.stadt || ""}
            required
            id="stadt"
            name="stadt"
            label="Stadt"
            fullWidth
            variant="standard"
            onChange={(e) => handleChange(e.target.value, "stadt")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!!userData}
            value={userData?.Kontaktperson || schuldatenInfo.kontakt || ""}
            id="kontakt"
            name="kontakt"
            label="Kontaktperson"
            fullWidth
            variant="standard"
            onChange={(e) => handleChange(e.target.value, "kontakt")}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Schuldaten
