import React from "react"
import { Grid, Typography, Paper } from "@mui/material"

import Main from "../Main"

const posts = [
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Bookay (www.bookay-buecher.de)
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography>Name des Betreibers:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Dmitrij Tjumenzew</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Anschrift:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Louis-Lewin-Str. 106 12627 Berlin</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>E-Mail-Kontakt:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>dmitrij.tjumenzew [at] bookay-buecher.de</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Telefon:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>0176 707 80 334</Typography>
      </Grid>
    </Grid>
  </Paper>,
]

const Impressum = () => {
  return (
    <>
      <Grid container spacing={5} sx={{ mt: 1 }}>
        <Main title="Impressum" posts={posts} fullSize />
      </Grid>
    </>
  )
}

export default Impressum
