import React from "react"
import { Grid, Typography, Card, CardActionArea, CardContent } from "@mui/material"

const LicencePaper = ({ disableLink, licence, handleSelect, isChosen }) => {

  return (
    <Grid
      onClick={() => {
        if (!disableLink) handleSelect(licence.id)
      }}
      item
      xs={12}
      md={12}>
      <CardActionArea component="a" disabled={disableLink}>
        <Card sx={{ display: "flex", backgroundColor: isChosen ? "primary.main" : "grey.200" }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography variant="h6">
              {licence.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {licence.subheader}
            </Typography>
            <Typography variant="subtitle1">
              {licence.shortDescription.map(line => line+" ")}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  )
}

export default LicencePaper
