import React, { useState, useContext, useEffect } from "react"
import { Box, Button, Grid, Snackbar, Alert } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import ActivePositions from "./ActivePositions"
import Chat from "./Chat"
import Search from "./Search"
import History from "./History"
import SettingsContext from "../../context/SettingsContext"

const Trading = () => {
  const { settings, setSettingsExternal } = useContext(SettingsContext)
  const [snackbarMsg, setSnackbarMsg] = useState("")
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [currentTab, setCurrentTab] = useState(settings.defaultTradingPage || "Bücherlisten")

  useEffect(() => {
    setSettingsExternal({ ...settings, defaultTradingPage: currentTab })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const TabList = [
    // component can be deleted and actually can be zusammengefasst with Tabs
    {
      name: "Bücherlisten",
    },
    {
      name: "Meine Bücher",
    },
    {
      name: "Chat",
    },
    {
      name: "Verlauf",
    },
  ]

  const Tabs = {
    Bücherlisten: <Search isMobile={isMobile} setCurrentTab={setCurrentTab} setSnackbarMsg={setSnackbarMsg} setSnackbarOpen={setSnackbarOpen} />,
    "Meine Bücher": (
      <ActivePositions goToChat={() => setCurrentTab("Chat")} isMobile={isMobile} setSnackbarMsg={setSnackbarMsg} setSnackbarOpen={setSnackbarOpen} />
    ),
    Verlauf: <History goToChat={() => setCurrentTab("Chat")} isMobile={isMobile} setSnackbarMsg={setSnackbarMsg} setSnackbarOpen={setSnackbarOpen} />,
    Chat: <Chat goToVerlauf={() => setCurrentTab("Verlauf")} isMobile={isMobile} setSnackbarMsg={setSnackbarMsg} setSnackbarOpen={setSnackbarOpen} />,
  }

  const headerContent = (
    <Grid container spacing="4vh">
      {TabList.map((t) => (
        <Grid container item xs={12} columns={10} key={t.name}>
          <Grid item xs={1}>
            <Box
              sx={{
                height: "8vh",
                width: "100%",
                visibility: "hidden",
              }}></Box>
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              onClick={() => setCurrentTab(t.name)}
              sx={{
                height: "8vh",
                width: "100%",
                // mx: "10%",
                textTransform: "none",
                boxShadow: "none",
                backgroundColor: currentTab === t.name ? "white" : "secondary.main",
                color: currentTab === t.name ? "secondary.main" : "white",
              }}>
              {t.name}
            </Button>
          </Grid>

          <Grid item xs={1}>
            <Box
              sx={{
                backgroundColor: "white",
                height: "8vh",
                width: "100%",
                visibility: currentTab === t.name ? "visible" : "hidden",
              }}></Box>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )

  return (
    <Box sx={{ height: "100vh", width: "100vw", backgroundColor: "primary.main" }}>
      <Header content={headerContent} showUserMenu isMobile={isMobile} setCurrentTab={setCurrentTab} />
      <Box
        id="pageScrollID"
        sx={{
          position: "fixed",
          top: isMobile ? 0 : "1vw",
          left: isMobile ? 0 : "12vw",
          borderRadius: isMobile ? 0 : "38px",
          backgroundColor: "white",
          height: isMobile ? "100vh" : "calc(100vh - 2vw)",
          width: isMobile ? "100vw" : "87vw",
          p: "1vw",
          overflow: "auto",
        }}>
        {Tabs[currentTab] || Tabs.Search}
        <Snackbar
          sx={{ color: "secondary.main" }}
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => {
            setSnackbarOpen(false)
            setSnackbarMsg("")
          }}>
          <Alert
            onClose={() => {
              setSnackbarOpen(false)
              setSnackbarMsg("")
            }}
            severity="success"
            sx={{ width: "100%" }}>
            {snackbarMsg}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  )
}

export default Trading
