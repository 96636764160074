import React from "react"
import { Route, Routes as SwitchRoutes, useLocation } from "react-router-dom"
import Schuladmin from "./pages/Bookmanagement/Schuladmin"
import SchoolHunting from "./pages/SchoolHunting/SchoolHunting"
import AuthenticatedArea from "./container/AuthenticatedArea"
import Trading from "./pages/Trading/Trading"
import Start from "./pages/Start/Start"
import { F_SCHOOLHUNTING, F_VERWALTUNG, F_TRADING, F_START, F_INDEX } from "./constants/routes"
import UserProvider from "./context/UserProvider"
import SettingsProvider from "./context/SettingsProvider"
import LicenceProvider from "./context/LicenceProvider"

const App = () => {
  const location = useLocation()

  return (
    <UserProvider>
      <SettingsProvider>
        <LicenceProvider>
          <SwitchRoutes location={location} role="main">
            <Route path={F_START} element={<Start />} />
            <Route path={F_INDEX} element={<Start />} />
            <Route path="/" element={<AuthenticatedArea reqauth="Admin" />}>
              <Route path={F_SCHOOLHUNTING} element={<SchoolHunting />} />
            </Route>
            <Route path="/" element={<AuthenticatedArea reqauth="Normal" />}>
              <Route path={F_TRADING} element={<Trading />} />
            </Route>
            <Route path="/" element={<AuthenticatedArea reqauth="Schuladmin" />}>
              <Route path={F_VERWALTUNG} element={<Schuladmin />} />
            </Route>
            <Route path="*" element={<Start />} />
          </SwitchRoutes>
        </LicenceProvider>
      </SettingsProvider>
    </UserProvider>
  )
}

export default App
