import React, { useState } from "react"
import { Box, TextField, Button, Typography, Link, Grid } from "@mui/material"
import LoginError from "../../../components/LoginError"
import { confirmCode } from "../../../fetch/login"

const ValidateEmail = ({ setCurrentPage }) => {
  const [errorMsg, setErrorMsg] = useState("")
  const [showLoginError, setShowLoginError] = useState("none")
  const [code, setCode] = useState("")

  let email = localStorage.getItem("validateEmail")
  if (email) {
    email = email.replace(/['"]+/g, "")
  } else {
    setCurrentPage("login")
  }

  const handleAuthenticate = async (event) => {
    event.preventDefault()
    try {
      const user = await confirmCode({ email, code })

      if (user.error) {
        setShowLoginError("inherit")
        setErrorMsg(user.message)
      } else {
        localStorage.removeItem("validateEmail")
        setCurrentPage("login")
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <Grid container>
      <Grid item xs={1} md={4}>
        <Box></Box>
      </Grid>
      <Grid item xs={10} md={4}>
        <Box
          component="form"
          onSubmit={handleAuthenticate}
          noValidate
          sx={{
            mx: "auto",
            mt: "5vh",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "primary.main",
            borderRadius: "12px",
            p: "5vh",
            width: "100%",
          }}>
          <Typography variant="body2" sx={{ color: "black" }}>
            Bestätigungs - Code
          </Typography>
          <Link
            variant="caption"
            href="#"
            sx={{
              color: "gray",
              textDecoration: "none",
            }}>
            Keinen Code bekommen? Überprüfe den Spam-Ordner!
          </Link>
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmationcode"
            name="confirmationcode"
            autoFocus
            onChange={(e) => setCode(e.target.value)}
            variant="outlined"
            sx={{ backgroundColor: "white", height: "3.5rem", borderRadius: "0.3rem" }}
          />
          <Button
            type="submit"
            fullWidth
            disabled={!(code.length > 5)}
            sx={{ mt: 3, mb: 2, color: "white", backgroundColor: "primary.main", height: "3.5rem" }}>
            Bestätigen
          </Button>
          <LoginError msg={errorMsg} visible={showLoginError} />
        </Box>
      </Grid>
      <Grid item xs={1} md={4}>
        <Box></Box>
      </Grid>
    </Grid>
  )
}

export default ValidateEmail
