import React, { useState } from "react"
import { Typography, IconButton, InputAdornment, Button, Dialog, DialogTitle, DialogContent, DialogActions, OutlinedInput } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const ChatDialog = ({ showFertigDialog, showCancelDialog, LID, Aktion, handleCancelPosition, cancelDialog, handleFinish }) => {
  const [negotiatedSum, setNegotiatedSum] = useState(0)

  let title = ""
  let content = null
  let buttons = null

  if (showFertigDialog) {
    title = Aktion ? "Kauf abschließen" : "Verkauf abschließen"
    content = (
      <>
        <Typography paragraph>
          {Aktion
            ? "Hiermit bestätige ich, dass ich das Buch empfangen, und das Geld übergeben habe."
            : "Hiermit bestätige ich, dass ich das Geld empfangen, und das Buch übergeben habe."}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: "3vh" }} paragraph>
          Für wie viel hast Du das Buch {Aktion ? "gekauft?" : "verkauft?"}
        </Typography>
        <OutlinedInput
          autoFocus
          fullWidth
          id="price"
          name="price"
          onChange={(event) => {
            let rawVal = event.target.value
            let a = rawVal.toString()
            let c = a.replace(",", ".")
            let b = c.replace(/\s/g, "")
            if (b === " " || b.length === 0 || isNaN(b) || (b.match(".") || []).length > 1) {
              setNegotiatedSum(0)
            } else {
              setNegotiatedSum(b)
            }
          }}
          value={negotiatedSum || ""}
          variant="outlined"
          endAdornment={<InputAdornment position="start">€</InputAdornment>}
        />
      </>
    )
    buttons = (
      <Button
        variant="contained"
        disabled={!negotiatedSum || negotiatedSum <= 0}
        fullWidth
        sx={{ width: "90%", mx: "5%", backgroundColor: "#3de637", "&:hover": { backgroundColor: "#b3ffb0" } }}
        onClick={() => {
          cancelDialog()
          setNegotiatedSum(0)
          const sum = negotiatedSum || 0
          handleFinish(LID, sum)
        }}>
        Abschließen
      </Button>
    )
  }

  if (showCancelDialog) {
    title = Aktion ? "Kauf abbrechen" : "Verkauf abbrechen"
    content = <Typography paragraph>Willst Du den {Aktion ? "Kauf" : "Verkauf"} dieses Buches abbrechen?</Typography>
    buttons = (
      <>
        <Button
          variant="contained"
          sx={{ width: "6vw", backgroundColor: "#3de637", "&:hover": { backgroundColor: "#b3ffb0" } }}
          onClick={cancelDialog}>
          Nein
        </Button>
        <Button
          variant="outlined"
          sx={{ width: "6vw", borderColor: "red", color: "red", "&:hover": { borderColor: "red", backgroundColor: "salmon" } }}
          onClick={() => {
            cancelDialog()
            setNegotiatedSum(0)
            handleCancelPosition(LID)
          }}>
          Ja
        </Button>
      </>
    )
  }

  return (
    <Dialog open onClose={cancelDialog}>
      <DialogTitle>
        {title}
        <IconButton sx={{ position: "absolute", right: "1vw", top: "1vh" }} onClick={cancelDialog}>
          <CloseIcon color="secondary" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          pt: "10vh",
        }}>
        {content}
      </DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  )
}

export default ChatDialog
