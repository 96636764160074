import React from "react"
import { Card, Grid, Typography } from "@mui/material"

const ActivePositionItem = ({ details, setCurrentAP, goToChat, icon, isMobile }) => {
  const { Titel, Klasse, Name, Is_Bundle, Status } = details
  return (
    <Card
      variant="outlined"
      onClick={() => setCurrentAP(details)}
      sx={{
        width: "90%",
        borderColor: Status === "matched" ? "primary.main" : "",
        mx: "5%",
        my: "1vh",
        height: "auto",
        px: "1vw",
        cursor: "pointer",
        "&:hover": { backgroundColor: Status === "matched" ? "rgba(152, 227, 156, 0.4)" : "lightgray" },
      }}>
      <Grid container alignItems="flex-end">
        <Grid item xs={isMobile ? 2 : 1}>
          {icon}
        </Grid>
        {isMobile ? (
          <Grid item xs={10}>
            <Typography align="left">{Titel}</Typography>
          </Grid>
        ) : (
          <Grid item xs={Status === "matched" ? (Is_Bundle ? 8.5 : 4) : Is_Bundle ? 11 : 6.5}>
            <Typography align="left">{Titel}</Typography>
          </Grid>
        )}
        {Is_Bundle || isMobile ? (
          <></>
        ) : (
          <>
            <Grid item xs={1}>
              <Typography align="right">{Klasse}. Kl.</Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Typography align="right">{Name}</Typography>
            </Grid>
          </>
        )}
        {Status === "matched" && !isMobile ? (
          <Grid item xs={2.5} onClick={() => goToChat()}>
            <Typography align="right" sx={{ color: "primary.main", textDecoration: "underline" }}>
              Zum Chat
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Card>
  )
}

export default ActivePositionItem
