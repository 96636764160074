
import React, { useEffect, useContext } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { F_START } from "../constants/routes"
import UserContext from "../context/UserContext"

const AuthenticatedArea = (role) => {
  const navigate = useNavigate()
  const { userData } = useContext(UserContext)
  useEffect(() => {
    if (role.reqauth === "All" && (!userData || userData === null || !userData.role)) {
      navigate(F_START)
    }
    if (role.reqauth !== "All" && (!userData || userData === null || userData.role !== role.reqauth)) {
      navigate(F_START)
    }
  }, [userData, role.reqauth, navigate])

  return <Outlet />
}
export default AuthenticatedArea
