import React, { useState, useEffect, useContext } from "react"
import { Typography, Button, ButtonGroup, Paper, Grid, Tooltip } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import ListAltIcon from "@mui/icons-material/ListAlt"
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone"
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone"
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone"
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone"

import { getBooksByClass } from "../../fetch/trading"
import BookDialog from "./BookDialog"
import EntryWarning from "../../components/EntryWarning"
import { makeEntry, getHistoryPositions, getActivePositions } from "../../fetch/trading"
import UserContext from "../../context/UserContext"

const CustomPaper = (props) => <Paper elevation={0} {...props} />

const Search = ({ isMobile, setCurrentTab, setSnackbarOpen, setSnackbarMsg }) => {
  const { userData } = useContext(UserContext)
  const schoolID = userData.SchulID
  const sid = userData.SID
  const [currentClass, setCurrentClass] = useState(7)
  const [books, setBooks] = useState([])
  const [validBooks, setValidBooks] = useState([])
  const [currentBundle, setCurrentBundle] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentBook, setCurrentBook] = useState(null)
  const [backendWarning, setBackendWarning] = useState(null)
  const [shouldForce, setShouldForce] = useState(false)
  const [allAP, setAllAP] = useState([])
  const [allHP, setAllHP] = useState([])
  const [transacted, setTransacted] = useState([])
  const [pending, setPending] = useState([])
  const [waiting, setWaiting] = useState([])

  const handleBuy = async () => {
    try {
      const entry = await makeEntry({ force: shouldForce, SID: sid, BID: currentBook.id, Aktion: 1, schoolID })
      if (entry.type === "success") {
        if (entry.info === "forced") {
          console.log("forced")
        } else {
          console.log("normal")
        }
        setSnackbarOpen(true)
        setSnackbarMsg("Buch zum Kauf eingestellt")
        setCurrentTab("Meine Bücher")
      } else {
        setBackendWarning({ entry, wantedAction: 1 })
      }
    } catch (e) {
      console.log(e.message)
    }
    setDialogOpen(false)
  }

  const handleSell = async () => {
    try {
      const entry = await makeEntry({ force: shouldForce, SID: sid, BID: currentBook.id, Aktion: 0, schoolID })
      if (entry.type === "success") {
        if (entry.info === "forced") {
          console.log("forced")
        } else {
          console.log("normal")
        }
        setSnackbarOpen(true)
        setSnackbarMsg("Buch zum Verkauf eingestellt")
        setCurrentTab("Meine Bücher")
      } else {
        setBackendWarning({ entry, wantedAction: 0 })
      }
    } catch (e) {
      console.log(e.message)
    }
    setDialogOpen(false)
  }

  const handleClick = (book) => {
    setCurrentBook(book)
    setDialogOpen(true)
  }

  const handleClose = () => {
    setCurrentBook(null)
    setDialogOpen(false)
    setBackendWarning(null)
  }

  useEffect(() => {
    getBooksByClass(currentClass, schoolID, setBooks)
  }, [currentClass, schoolID])

  useEffect(() => {
    const filtered = books.filter((b) => !b.Is_Bundle)
    const bundle = books.filter((b) => b.Is_Bundle)
    setValidBooks(filtered)
    setCurrentBundle(bundle[0])
  }, [books])

  useEffect(() => {
    if (shouldForce) {
      if (backendWarning?.wantedAction) {
        handleBuy()
      } else {
        handleSell()
      }
    }
    setBackendWarning(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldForce])

  useEffect(() => {
    getHistoryPositions({ SID: sid }, setAllHP)
    getActivePositions({ SID: sid }, setAllAP)
  }, [sid])

  useEffect(() => {
    const filteredTransacted = allHP.map((p) => p.Titel)
    const filteredPendingRaw = allAP.filter((p) => p.Status === "matched")
    const filteredPending = filteredPendingRaw.map((p) => p.Titel)
    const filteredWaitingRaw = allAP.filter((p) => p.Status === "suche")
    const filteredWaiting = filteredWaitingRaw.map((p) => p.Titel)
    setTransacted(filteredTransacted)
    setPending(filteredPending)
    setWaiting(filteredWaiting)
  }, [allAP, allHP])

  const rows = Object.keys(validBooks).map((key) => validBooks[key])

  return (
    <>
      <Grid container justifyContent="space-between" sx={{ ml: "1vw", mt: "1vw", width: "98%" }}>
        <Grid item xs={isMobile ? 12 : undefined}>
          <Typography
            variant="h5"
            align={isMobile ? "center" : "left"}
            sx={{ color: "secondary.main", width: isMobile ? "98vw" : "auto", mb: isMobile ? 4 : 0 }}>
            {currentClass ? `Bücherliste Klasse ${currentClass}` : "Extrabücher"}
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : undefined}>
          <Button
            sx={{ visibility: currentClass > 0 ? "visible" : "hidden", width: isMobile ? "90%" : "auto", mx: isMobile ? "5%" : "inherit" }}
            variant="outlined"
            color="secondary"
            startIcon={<ListAltIcon color="secondary" />}
            onClick={() => {
              setCurrentBook(currentBundle)
              setDialogOpen(true)
            }}>
            Alle Bücher zusammen kaufen
          </Button>
        </Grid>
      </Grid>

      <Paper
        elevation={6}
        sx={{ width: isMobile ? "90%" : "calc(100% - 2vw)", mx: isMobile ? "5%" : "1vw", mt: isMobile ? 5 : "5vh", borderRadius: "20px" }}>
        <ButtonGroup
          variant="outlined"
          sx={{ width: "100%", height: "5vh", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", overflow: "hidden" }}>
          {[7, 8, 9, 10, 11, 12, 13, 0].map((c) => (
            <Button
              onClick={() => setCurrentClass(c)}
              fullWidth
              key={c}
              sx={{
                backgroundColor: c !== currentClass ? "primary.main" : "white",
                color: c === currentClass ? "secondary.main" : "secondary.main",
                "&:last-child": { borderTopRightRadius: "20px" },
                "&:first-child": { borderTopLeftRadius: "20px" },
              }}>
              {c ? c : isMobile ? "☆" : "Extra"}
            </Button>
          ))}
        </ButtonGroup>

        <TableContainer
          sx={{ borderBottomRightRadius: "20px", borderBottomLeftRadius: "20px", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          component={CustomPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "5%" }}>
                  <Tooltip arrow title="Buch gekauft oder verkauft" placement="top">
                    <CheckBoxOutlineBlankTwoToneIcon />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: isMobile ? "95%" : "25%" }}>Titel</TableCell>
                {isMobile ? (
                  <></>
                ) : (
                  <>
                    <TableCell sx={{ width: "5%" }} align="right">
                      Fach
                    </TableCell>
                    <TableCell sx={{ width: "15%" }} align="right">
                      ISBN
                    </TableCell>
                    <TableCell sx={{ width: "15%" }} align="right">
                      Verlag
                    </TableCell>
                    <TableCell sx={{ width: "10%" }} align="right">
                      Originalpreis
                    </TableCell>
                    <TableCell sx={{ width: "25%" }} align="right">
                      Information
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow onClick={() => handleClick(row)} key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {transacted.includes(row.Titel) ? (
                      <Tooltip arrow placement="top" title="Abgeschlossen">
                        <CheckBoxTwoToneIcon />
                      </Tooltip>
                    ) : pending.includes(row.Titel) ? (
                      <Tooltip arrow placement="top" title="Partner gefunden">
                        <ChatTwoToneIcon sx={{ color: "primary.main" }} />
                      </Tooltip>
                    ) : waiting.includes(row.Titel) ? (
                      <Tooltip arrow placement="top" title="Partner wird gesucht...">
                        <SearchTwoToneIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip arrow placement="top" title="Nicht eingestellt">
                        <CheckBoxOutlineBlankTwoToneIcon />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.Titel}
                  </TableCell>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                      <TableCell align="right">{row.Fach}</TableCell>
                      <TableCell align="right">{row.ISBN}</TableCell>
                      <TableCell align="right">{row.Verlag}</TableCell>
                      <TableCell align="right">{row.Originalpreis ? `${row.Originalpreis} €` : ""}</TableCell>
                      <TableCell align="right">{row.Kommentar}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {currentBook && (
        <BookDialog handleClose={handleClose} open={dialogOpen} book={currentBook} buy={handleBuy} sell={handleSell} isMobile={isMobile} />
      )}
      {backendWarning && <EntryWarning wantedAction={backendWarning.wantedAction} setShouldForce={setShouldForce} warning={backendWarning.entry} />}
    </>
  )
}

export default Search
