import React, { useState, useEffect, useContext } from "react"
import { Typography, Grid, Divider, Accordion, AccordionDetails, AccordionSummary, Box, Tooltip } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import HelpSharpIcon from "@mui/icons-material/HelpSharp"
import { getHistoryPositions } from "../../fetch/trading"
import HistoryItem from "./HistoryItem"
import HistoryDialog from "./HistoryDialog"
import HistoryCalculation from "./HistoryCalculation"
import UserContext from "../../context/UserContext"

const makeHistoryCalculations = (k, v) => {
  const countK = k.length
  let sumK = 0
  let orgK = 0
  k.forEach((e) => {
    sumK += e?.VerhandelterPreis
    orgK += e?.Originalpreis
  })
  const gespart = orgK - sumK

  const countV = v.length
  let sumV = 0
  let orgV = 0
  v.forEach((e) => {
    sumV += e?.VerhandelterPreis
    orgV += e?.Originalpreis
  })
  const wiedergewonnen = orgV - sumV

  return {
    kaufen: {
      anzahl: countK,
      summe: sumK,
      originalSumme: orgK,
      gespart: gespart,
    },
    verkaufen: {
      anzahl: countV,
      summe: sumV,
      originalSumme: orgV,
      wiedergewonnen: wiedergewonnen,
    },
  }
}

const History = ({ goToChat, isMobile }) => {
  const { userData } = useContext(UserContext)
  const sid = userData.SID
  const [allHP, setAllHP] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [allHistoryPosK, setAllHistoryPosK] = useState([])
  const [allHistoryPosV, setAllHistoryPosV] = useState([])
  const [calculations, setCalculations] = useState(null)
  const [currentHP, setCurrentHP] = useState(null)

  useEffect(() => {
    getHistoryPositions({ SID: sid }, setAllHP)
  }, [sid])

  useEffect(() => {
    const history = [...allHP]
    const historyK = history.filter((p) => p.Aktion)
    const historyV = history.filter((p) => !p.Aktion)
    setAllHistoryPosK(historyK)
    setAllHistoryPosV(historyV)
    const hKnoBundles = historyK.filter((p) => !p.Is_Bundle)
    const hVnoBundles = historyV.filter((p) => !p.Is_Bundle)
    const calc = makeHistoryCalculations(hKnoBundles, hVnoBundles)
    setCalculations(calc)
  }, [allHP])

  useEffect(() => {
    setModalOpen(!!currentHP)
  }, [currentHP])

  return (
    <>
      <Typography
        variant="h5"
        align={isMobile ? "center" : "left"}
        sx={{ color: "secondary.main", width: isMobile ? "96vw" : "auto", mb: isMobile ? 4 : 0, ml: "1vw", pt: "1vw" }}>
        Verlauf
      </Typography>

      <Box sx={{ width: "calc(100% - 2vw)", mx: "1vw", mt: "5vh" }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Auswertung</Typography>
            <Tooltip
              title="Ergebnisse können abweichen, wenn von der Schule kein Originalpreis angegeben wurde. Bücherpakete werden nicht berücksichtigt."
              arrow
              placement="right">
              <HelpSharpIcon sx={{ color: "grey", ml: 1 }} />
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>{calculations && <HistoryCalculation calc={calculations} isMobile={isMobile} />}</AccordionDetails>
        </Accordion>
      </Box>

      <Grid container justifyContent="space-evenly" sx={{ height: isMobile ? "auto" : "90%", mt: isMobile ? 5 : "5vh" }}>
        <Grid item xs={5.9}>
          <Typography variant="subtitle1" align="center">
            Ich habe gekauft
          </Typography>
          {allHistoryPosK.length > 0 && allHistoryPosK.map((p) => <HistoryItem setCurrentHP={setCurrentHP} key={p.LID} details={p} />)}
        </Grid>
        <Grid item xs={0.2}>
          <Divider orientation="vertical" sx={{ width: isMobile ? "auto" : "70%" }} />
        </Grid>
        <Grid item xs={5.9}>
          <Typography variant="subtitle1" align="center">
            Ich habe verkauft
          </Typography>
          {allHistoryPosV.length > 0 && allHistoryPosV.map((p) => <HistoryItem setCurrentHP={setCurrentHP} key={p.LID} details={p} />)}
        </Grid>
      </Grid>

      {currentHP && (
        <HistoryDialog
          goToChat={goToChat}
          open={modalOpen}
          details={currentHP}
          handleClose={() => {
            setModalOpen(false)
            setCurrentHP(null)
          }}
        />
      )}
    </>
  )
}

export default History
