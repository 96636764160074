import React from "react"
import { Grid, Typography, Paper } from "@mui/material"

import Main from "../Main"

const posts = [
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Bookay
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography>Kontaktperson:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Dmitrij Tjumenzew</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>E-Mail-Kontakt:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>dmitrij.tjumenzew [at] bookay-buecher.de</Typography>
      </Grid>
      {/* <Grid item xs={6}>
        <Typography>WhatsApp:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Link href="https://wa.me/message/7DZK6MFHWSSHO1" target="_blank" rel="noopener noreferrer">
          WhatsApp-Link
        </Link>
      </Grid> */}
    </Grid>
  </Paper>,
]

const Kontakt = () => {
  return (
    <>
      <Grid container spacing={5} sx={{ mt: 1 }}>
        <Main title="Kontakt" posts={posts} fullSize />
      </Grid>
    </>
  )
}

export default Kontakt
