import React from "react"
import { Grid } from "@mui/material"

import MainFeaturedPost from "../MainFeaturedPost"
import FeaturedPost from "../FeaturedPost"

import image from "../img/schueler2.png"
import coppi from "../img/coppi.png"
import kniese from "../img/kniese.png"
import orwell from "../img/orwell.png"
import satp from "../img/satp.png"

const mainFeaturedPost = {
  title: "Bookay Partnerschulen",
  description: "Diese Schulen verwenden die Bookay Schulbuchplattform",
  image: image,
  imageText: "Main Image",
  linkText: "",
}

const featuredPosts = [
  {
    title: "Hans-und-Hilde-Coppi-Gymnasium",
    date: "Berlin",
    // description:
    //   "Am Hans-und-Hilde-Coppi-Gymnasium in Berlin enstand 2021 Bookay aus einem Informatikprojekt. Im gleichen Jahr wurde es die erste Partnerschule von Bookay - Hier kaufen und verkaufen 700+ Schüler ihre Bücher schon das 3. Jahr über unsere App.",
    image: coppi,
    imageLabel: "School",
    pageName: "Von Schülern - Für Schüler",
    subTitle: " ",
    // subTitle: "Hier mehr dazu...",
    year: 2021,
  },
  {
    title: "George-Orwell-Oberschule",
    date: "Berlin",
    // description:
    //   "Eine große Integrierte Sekundarschule mit gymnasialer Oberstufe. Da es hier eine schulübergreifende Oberstufe gibt, wurde ein individuelles System entwickelt. Hier kaufen und verkaufen 750+ Schüler ihre Bücher schon das 2. Jahr über unsere App.",
    image: orwell,
    imageLabel: "School",
    pageName: "Lizenzen und Module",
    subTitle: " ",
    // subTitle: "Hier zu schulspezifischen Modulen...",
    year: 2022,
  },
  {
    title: "Paul-und-Charlotte-Kniese-Schule",
    date: "Berlin",
    // description:
    //   "Eine Gemeinschaftsschule in Berlin. Auch hier wurde ein individuelles System entwickelt. Schon das 2. Jahr kaufen hier Schüler ihre Bücher über unsere App.",
    image: kniese,
    imageLabel: "School",
    pageName: "Lizenzen und Module",
    subTitle: " ",
    // subTitle: "Hier zu schulspezifischen Modulen...",
    year: 2022,
  },
  {
    title: "Schule am Tierpark",
    date: "Berlin",
    image: satp,
    imageLabel: "School",
    pageName: "Lizenzen und Module",
    subTitle: " ",
    year: 2023,
  },
]

const Partner = ({ setCurrentPage }) => {
  return (
    <>
      <MainFeaturedPost post={mainFeaturedPost} align="right" />
      <Grid container spacing={4}>
        {featuredPosts.map((post) => (
          <FeaturedPost objectFit disableLink setCurrentPage={setCurrentPage} isMain key={post.title} post={post} />
        ))}
      </Grid>
    </>
  )
}

export default Partner
