import React, { useState, useEffect, useContext } from "react"
import { Typography, Grid, Divider, Tooltip } from "@mui/material"
import { getActivePositions, cancelActivePosition, cancelMatchedPosition } from "../../fetch/trading"
import ActivePositionItem from "./ActivePositionItem"
import ActivePositionDialog from "./ActivePositionDialog"
import UserContext from "../../context/UserContext"
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone"
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone"

const ActivePositions = ({ goToChat, isMobile, setSnackbarMsg, setSnackbarOpen }) => {
  const { userData } = useContext(UserContext)
  const sid = userData.SID
  const [allAP, setAllAP] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [allSuchePosK, setAllSuchePosK] = useState([])
  const [allSuchePosV, setAllSuchePosV] = useState([])
  const [allMatchedPosK, setAllMatchedPosK] = useState([])
  const [allMatchedPosV, setAllMatchedPosV] = useState([])
  const [currentAP, setCurrentAP] = useState(null)

  useEffect(() => {
    getActivePositions({ SID: sid }, setAllAP)
  }, [sid])

  useEffect(() => {
    const suche = allAP.filter((p) => p.Status === "suche")
    const sucheK = suche.filter((p) => p.Aktion)
    const sucheV = suche.filter((p) => !p.Aktion)
    setAllSuchePosK(sucheK)
    setAllSuchePosV(sucheV)
    const matched = allAP.filter((p) => p.Status === "matched")
    const matchedK = matched.filter((p) => p.Aktion)
    const matchedV = matched.filter((p) => !p.Aktion)
    setAllMatchedPosK(matchedK)
    setAllMatchedPosV(matchedV)
  }, [allAP])

  useEffect(() => {
    setModalOpen(!!currentAP)
  }, [currentAP])

  const handleCancelActive = () => {
    if (currentAP) {
      try {
        const { LID } = currentAP
        const { Aktion } = currentAP
        const cancel = cancelActivePosition({ LID })
        if (cancel.error) {
          console.log(cancel.error)
        } else {
          setModalOpen(false)
          setCurrentAP(null)
          let copyAllAP = [...allAP]
          const copy = copyAllAP.filter((p) => p.LID !== LID)
          setAllAP(copy)
          setSnackbarOpen(true)
          setSnackbarMsg(Aktion ? "Kauf abgebrochen" : "Verkauf abgebrochen")
        }
      } catch (e) {
        console.log(e.message)
      }
    }
  }

  const handleCancelMatched = () => {
    if (currentAP) {
      try {
        const { LID } = currentAP
        const { Aktion } = currentAP
        const cancel = cancelMatchedPosition({ LID })
        if (cancel.error) {
          console.log(cancel.error)
        } else {
          setModalOpen(false)
          setCurrentAP(null)
          let copyAllAP = [...allAP]
          const copy = copyAllAP.filter((p) => p.LID !== LID)
          setAllAP(copy)
          setSnackbarOpen(true)
          setSnackbarMsg(Aktion ? "Kauf abgebrochen" : "Verkauf abgebrochen")
        }
      } catch (e) {
        console.log(e.message)
      }
    }
  }

  return (
    <>
      <Typography
        variant="h5"
        align={isMobile ? "center" : "left"}
        sx={{ color: "secondary.main", ml: "1vw", pt: "1vw", width: isMobile ? "96vw" : "auto", mb: isMobile ? 4 : 0 }}>
        Meine Bücher
      </Typography>

      <Grid container justifyContent="space-evenly" sx={{ height: "90%", mt: "5vh" }}>
        <Grid item xs={5.9}>
          <Typography variant="subtitle1" align="center">
            Ich kaufe:
          </Typography>
          <Typography variant="subtitle2" align="center" sx={{ mt: "1vh" }}>
            Partner wird gesucht
          </Typography>
          {allSuchePosK.length > 0 &&
            allSuchePosK.map((p) => (
              <ActivePositionItem
                isMobile={isMobile}
                icon={
                  <Tooltip arrow placement="top" title="Partner wird gesucht...">
                    <SearchTwoToneIcon sx={{ verticalAlign: "bottom", mt: "10%" }} />
                  </Tooltip>
                }
                setCurrentAP={setCurrentAP}
                key={p.LID}
                details={p}
              />
            ))}
          <Divider sx={{ mt: "3vh", width: "90%", mx: "5%" }} />
          <Typography variant="subtitle2" align="center" sx={{ mt: "1vh" }}>
            Partner gefunden
          </Typography>
          {allMatchedPosK.length > 0 &&
            allMatchedPosK.map((p) => (
              <ActivePositionItem
                isMobile={isMobile}
                icon={
                  <Tooltip arrow placement="top" title="Partner gefunden">
                    <ChatTwoToneIcon sx={{ color: "primary.main", verticalAlign: "bottom", mt: "10%" }} />
                  </Tooltip>
                }
                setCurrentAP={setCurrentAP}
                key={p.LID}
                details={p}
              />
            ))}
        </Grid>
        <Grid item xs={0.2}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5.9}>
          <Typography variant="subtitle1" align="center">
            Ich verkaufe:
          </Typography>

          <Typography variant="subtitle2" align="center" sx={{ mt: "1vh" }}>
            Partner wird gesucht
          </Typography>
          {allSuchePosV.length > 0 &&
            allSuchePosV.map((p) => (
              <ActivePositionItem
                isMobile={isMobile}
                icon={
                  <Tooltip arrow placement="top" title="Partner wird gesucht...">
                    <SearchTwoToneIcon sx={{ verticalAlign: "bottom", mt: "10%" }} />
                  </Tooltip>
                }
                goToChat={goToChat}
                setCurrentAP={setCurrentAP}
                key={p.LID}
                details={p}
              />
            ))}
          <Divider sx={{ mt: "3vh", width: "90%", mx: "5%" }} />
          <Typography variant="subtitle2" align="center" sx={{ mt: "1vh" }}>
            Partner gefunden
          </Typography>
          {allMatchedPosV.length > 0 &&
            allMatchedPosV.map((p) => (
              <ActivePositionItem
                isMobile={isMobile}
                icon={
                  <Tooltip arrow placement="top" title="Partner gefunden">
                    <ChatTwoToneIcon sx={{ color: "primary.main", verticalAlign: "bottom", mt: "10%" }} />
                  </Tooltip>
                }
                goToChat={goToChat}
                setCurrentAP={setCurrentAP}
                key={p.LID}
                details={p}
              />
            ))}
        </Grid>
      </Grid>

      {currentAP && (
        <ActivePositionDialog
          goToChat={goToChat}
          handleCancel={currentAP.Status === "suche" ? handleCancelActive : handleCancelMatched}
          open={modalOpen}
          details={currentAP}
          handleClose={() => {
            setModalOpen(false)
            setCurrentAP(null)
          }}
        />
      )}
    </>
  )
}

export default ActivePositions
