const api = process.env.REACT_APP_BACKEND_HOST

// Frontend Routes

export const F_INDEX = "/"
export const F_START = "/start"
export const F_SCHOOLHUNTING = "/schoolhunting"
export const F_VERWALTUNG = "/verwaltung"
export const F_TRADING = "/trading"

// Backend Routes

export const BACKEND_SCHUELER_LOGIN = `${api}/?action=schueler&type=login`
export const BACKEND_SCHUELER_REGISTER = `${api}/?action=schueler&type=register`
export const BACKEND_SCHUELER_VALIDATECODE = `${api}/?action=schueler&type=validatecode`
export const BACKEND_SCHUELER_FORGOT = `${api}/?action=schueler&type=forgot`
export const BACKEND_SCHUELER_NEWPW = `${api}/?action=schueler&type=newpw`

export const BACKEND_CONTACTS_ADDCONTACT = `${api}/?action=contacts&type=addcontact`
export const BACKEND_CONTACTS_ALLCONTACTS = `${api}/?action=contacts&type=allcontacts`
export const BACKEND_CONTACTS_ALLCITIES_BL = `${api}/?action=contacts&type=allcities&bl=`

export const BACKEND_FAECHER_ALL = `${api}/?action=faecher&type=all`
export const BACKEND_FAECHER_ADDSUBJECT = `${api}/?action=faecher&type=addsubject`

export const BACKEND_BUCHVERWALTUNG_ALL_ID = `${api}/?action=buchverwaltung&type=all&id=`
export const BACKEND_BUCHVERWALTUNG_ADDBOOK = `${api}/?action=buchverwaltung&type=addbook`
export const BACKEND_BUCHVERWALTUNG_DELETEBOOK = `${api}/?action=buchverwaltung&type=deletebook`
export const BACKEND_BUCHVERWALTUNG_UPDATEBOOK = `${api}/?action=buchverwaltung&type=updatebook`

export const BACKEND_TRADING_ALLBOOKS = `${api}/?action=trading&type=allbooks`
export const BACKEND_TRADING_ENTRY = `${api}/?action=trading&type=entry`
export const BACKEND_TRADING_ALLACTIVEPOSITIONS = `${api}/?action=trading&type=allactivepositions`
export const BACKEND_TRADING_CANCELACTIVEPOSITION = `${api}/?action=trading&type=cancelactiveposition`
export const BACKEND_TRADING_ALLHISTORYPOSITIONS = `${api}/?action=trading&type=allhistorypositions`
export const BACKEND_TRADING_CANCELMATCHEDPOSITION = `${api}/?action=trading&type=cancelmatchedposition`
export const BACKEND_TRADING_FINISHMATCHEDPOSITION = `${api}/?action=trading&type=finishmatchedposition`

export const BACKEND_CHAT_GETCHATS = `${api}/?action=chat&type=getchats`
export const BACKEND_CHAT_SENDMSG = `${api}/?action=chat&type=sendmsg`

export const BACKEND_CHECKOUT_NEUKUNDE = `${api}/?action=checkout&type=neukunde`
export const BACKEND_CHECKOUT_ASKFORMORE = `${api}/?action=checkout&type=askformore`
export const BACKEND_CHECKOUT_SAVEPW = `${api}/?action=checkout&type=savepw`
