import { fetchHeaders } from "./fetchHeaders"
import {
  BACKEND_TRADING_ENTRY,
  BACKEND_TRADING_ALLBOOKS,
  BACKEND_TRADING_ALLACTIVEPOSITIONS,
  BACKEND_TRADING_CANCELACTIVEPOSITION,
  BACKEND_TRADING_ALLHISTORYPOSITIONS,
  BACKEND_TRADING_CANCELMATCHEDPOSITION,
  BACKEND_TRADING_FINISHMATCHEDPOSITION,
} from "../constants/routes"

export const getBooksByClass = (classNumber, schoolID, setAll) => {
  return fetch(BACKEND_TRADING_ALLBOOKS, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify({ classNumber, schoolID }),
  })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      let result = Object.keys(data).map((key) => data[key]) // converts the data object to an array
      setAll(result)
    })
}

export const makeEntry = (entryData) => {
  return fetch(BACKEND_TRADING_ENTRY, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(entryData),
  }).then((response) => {
    return response.json()
  })
}

export const getActivePositions = (data, setAll) => {
  return fetch(BACKEND_TRADING_ALLACTIVEPOSITIONS, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json()
    })
    .then((res) => {
      let result = Object.keys(res).map((key) => res[key]) // converts the data object to an array
      setAll(result)
    })
}

export const cancelActivePosition = (data) => {
  return fetch(BACKEND_TRADING_CANCELACTIVEPOSITION, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json()
  })
}

export const getHistoryPositions = (data, setAll) => {
  return fetch(BACKEND_TRADING_ALLHISTORYPOSITIONS, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json()
    })
    .then((res) => {
      let result = Object.keys(res).map((key) => res[key]) // converts the data object to an array
      setAll(result)
    })
}

export const cancelMatchedPosition = (data) => {
  return fetch(BACKEND_TRADING_CANCELMATCHEDPOSITION, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json()
  })
}

export const finishMatchedPosition = (data) => {
  return fetch(BACKEND_TRADING_FINISHMATCHEDPOSITION, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json()
  })
}
