import React from "react"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import bmbfLogo from "./img/bmbf/bmbf.png"

const Copyright = ({ setCurrentPage }) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#" onClick={() => setCurrentPage("impressum")}>
        Dmitrij Tjumenzew
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const Footer = ({ setCurrentPage }) => {
  return (
    <Box component="footer" sx={{ bgcolor: "background.paper", py: 6 }}>
      <Container maxWidth="lg">
        <img
          alt="BMBF"
          src={bmbfLogo}
          style={{
            display: "block",
            margin: "0 auto",
            height: "150px",
          }}
        />
        <Typography variant="h6" align="center" gutterBottom>
          Bookay {new Date().getFullYear()}
        </Typography>
        <Typography variant="subtitle1" align="center" component="p">
          <Link href="#" sx={{ textDecoration: "none" }} color="text.secondary" onClick={() => setCurrentPage("impressum")}>
            Impressum |{" "}
          </Link>
          <Link href="#" sx={{ textDecoration: "none" }} color="text.secondary" onClick={() => setCurrentPage("datenschutz")}>
            {" "}
            Datenschutzerklärung |{" "}
          </Link>
          <Link href="#" sx={{ textDecoration: "none" }} color="text.secondary" onClick={() => setCurrentPage("agb")}>
            AGB
          </Link>
        </Typography>
        <Copyright setCurrentPage={setCurrentPage} />
      </Container>
    </Box>
  )
}

export default Footer
