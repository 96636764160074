import React from "react"
import { Button, IconButton, Dialog, DialogTitle, Slide, DialogContent, Paper, Typography, Divider } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import CloseIcon from "@mui/icons-material/Close"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ActivePositionDialog = ({ handleClose, open, details, handleCancel, goToChat }) => {
  const { Titel, Is_Bundle, Klasse, Name, Originalpreis, Verlag, Kommentar, ISBN, Aktion, Status } = details
  return (
    <Dialog TransitionComponent={Transition} onClose={handleClose} open={open}>
      <DialogTitle>
        {Titel}
        <IconButton sx={{ position: "absolute", right: "1vw", top: "1vh" }} onClick={handleClose}>
          <CloseIcon color="secondary" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: "auto",
        }}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="left">{Aktion ? "Ich kaufe" : "Ich verkaufe"}</TableCell>
                <TableCell align="right" />
              </TableRow>
              <TableRow>
                <TableCell align="left">Klasse</TableCell>
                <TableCell align="right">{Klasse}</TableCell>
              </TableRow>
              {!Is_Bundle && (
                <>
                  <TableRow>
                    <TableCell align="left">Fach</TableCell>
                    <TableCell align="right">{Name}</TableCell>
                  </TableRow>
                  {ISBN ? (
                    <TableRow>
                      <TableCell align="left">ISBN</TableCell>
                      <TableCell align="right">{ISBN}</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                  {Verlag ? (
                    <TableRow>
                      <TableCell align="left">Verlag</TableCell>
                      <TableCell align="right">{Verlag}</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                  {Originalpreis ? (
                    <TableRow>
                      <TableCell align="left">Originalpreis</TableCell>
                      <TableCell align="right">{Originalpreis} €</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <TableRow>
                <TableCell align="left">Endültiger Preis</TableCell>
                <TableCell align="right">Nach Absprache mit Partner</TableCell>
              </TableRow>
              {Is_Bundle || Kommentar ? (
                <TableRow>
                  <TableCell align="left">Information</TableCell>
                  <TableCell align="right">{Is_Bundle ? `Alle Bücher der Klasse ${Klasse} zusammen.` : Kommentar}</TableCell>
                </TableRow>
              ) : (
                <></>
              )}
              {Status === "matched" ? (
                <TableRow onClick={() => goToChat()} sx={{ cursor: "pointer" }}>
                  <TableCell align="left">Partner</TableCell>
                  <TableCell align="right" sx={{ textDecoration: "underline" }}>
                    Chat
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Button variant="contained" onClick={handleCancel} fullWidth sx={{ mt: 2, backgroundColor: "salmon" }}>
          {Aktion ? "Kauf" : "Verkauf"} abbrechen
        </Button>

        <Divider sx={{ mt: "3vh" }} />

        <Typography sx={{ mt: "1vh", color: "gray" }} variant="caption">
          Es wird lediglich ein Partner gesucht und ein engültiger Kauf / Verkauf ist nicht garantiert.
          <br /> Preis nach Absprache mit dem Partner.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default ActivePositionDialog
