import { fetchHeaders } from "./fetchHeaders"
import { BACKEND_CHECKOUT_NEUKUNDE, BACKEND_CHECKOUT_SAVEPW, BACKEND_CHECKOUT_ASKFORMORE } from "../constants/routes"

export const makeNeukunde = (data) => {
  return fetch(BACKEND_CHECKOUT_NEUKUNDE, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json()
  })
}

export const askForMore = (data) => {
  return fetch(BACKEND_CHECKOUT_ASKFORMORE, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json()
  })
}

export const savePW = (newP) => {
  return fetch(BACKEND_CHECKOUT_SAVEPW, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(newP),
  }).then((data) => data.json())
}
