import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Typography, MenuItem, FormControl, ListItemText, Menu, Button, Grid } from "@mui/material"
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone"
import UserContext from "../context/UserContext"
import { F_START, F_TRADING, F_VERWALTUNG } from "../constants/routes"

const UserMenu = ({ showName, mainPage, bookmanagement, handleLogout }) => {
  const navigate = useNavigate()
  const { userData } = useContext(UserContext)
  let nav = F_TRADING
  if (mainPage) {
    if (userData?.role === "Schuladmin") {
      nav = F_VERWALTUNG
    } else {
      nav = F_TRADING
    }
  }
  const [openMenu, setOpenMenu] = useState(null)
  const open = Boolean(openMenu)

  const handleClose = () => {
    setOpenMenu(null)
  }
  const handleOpen = (event) => {
    setOpenMenu(event.currentTarget)
  }

  return mainPage ? (
    <Box>
      <Box
        component={Button}
        display={{ xs: "none", md: "inline-flex" }}
        size="large"
        onClick={handleOpen}
        sx={{ float: "right", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        endIcon={<SchoolTwoToneIcon />}>
        {userData?.Schulname || "Schulaccount"}
      </Box>

      <FormControl sx={{ visibility: "none" }}>
        <Menu
          id="user-menu"
          open={open}
          onClose={handleClose}
          anchorEl={openMenu}
          keepMounted
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <MenuItem value="1" key="1">
            <ListItemText onClick={() => navigate(nav)}>Zur App</ListItemText>
          </MenuItem>
          <MenuItem value="2" key="2">
            <ListItemText onClick={handleLogout}>Log out</ListItemText>
          </MenuItem>
        </Menu>
      </FormControl>
    </Box>
  ) : (
    <Box
      sx={{
        fontSize: "inherit",
        width: "80%",
        mx: "auto",
        visibility: showName ? "visible" : "hidden",
      }}>
      <Button
        variant="contained"
        fullWidth
        onClick={handleOpen}
        sx={{
          mt: "1vh",
          boxShadow: "none",
          height: "15vh",
          backgroundColor: bookmanagement ? "bmprimary.main" : "initial",
          "&:hover": {
            backgroundColor: bookmanagement ? "bmsecondary.main" : "initial",
          },
        }}>
        <Grid container>
          <Grid item xs={12}>
            <SchoolTwoToneIcon sx={{ color: "white", mx: "auto" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: "white", textTransform: "none" }}>
              {userData?.Schulname}
            </Typography>
          </Grid>
        </Grid>
      </Button>

      <FormControl sx={{ visibility: "none" }}>
        <Menu
          id="user-menu"
          open={open}
          onClose={handleClose}
          anchorEl={openMenu}
          keepMounted
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          {!mainPage && (
            <MenuItem value="3" key="3">
              <ListItemText onClick={() => navigate(F_START)}>Zur Startseite</ListItemText>
            </MenuItem>
          )}
          {mainPage && (
            <MenuItem value="1" key="1">
              <ListItemText onClick={() => navigate(nav)}>Zur App</ListItemText>
            </MenuItem>
          )}
          <MenuItem value="2" key="2">
            <ListItemText onClick={handleLogout}>Log out</ListItemText>
          </MenuItem>
        </Menu>
      </FormControl>
    </Box>
  )
}
export default UserMenu
