import * as React from "react"
import { Grid, Typography, Card, CardActionArea, CardContent, CardMedia } from "@mui/material"

const highlightStyle = { border: "2px solid", borderColor: "primary.main", borderRadius: "4px", my: 2 }

const FeaturedPost = ({ disableLink, post, isMain, setCurrentPage, objectFit }) => {
  if (!post) {
    return <></>
  }
  return (
    <Grid
      onClick={() => {
        if (!disableLink) setCurrentPage(post.pageName)
      }}
      item
      xs={12}
      md={isMain ? 12 : 6}>
      <CardActionArea component="a" disabled={disableLink}>
        <Card sx={{ display: "flex", ...(post?.highlight && highlightStyle) }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              {post.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {post.date}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {post.description}
            </Typography>
            {post.subTitle && (
              <Typography variant="subtitle1" color="primary">
                {post.subTitle}
              </Typography>
            )}
            {post.year && (
              <Typography variant="subtitle1" color="primary">
                {post.year}
              </Typography>
            )}
          </CardContent>
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: "none", sm: "block" }, objectFit: objectFit ? "contain" : null }}
            src={post.image}
            alt={post.imageLabel}
          />
        </Card>
      </CardActionArea>
    </Grid>
  )
}

export default FeaturedPost
