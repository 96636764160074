import React, { useState, useEffect, useContext } from "react"
import { Box, TextField, Button, MenuItem, Autocomplete, Grid } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import Header from "../../components/Header"
import {getContacts, getCities, addContact} from "../../fetch/schoolhunting"
import LoginError from "../../components/LoginError"
import {alleBundeslaender, alleAktuellenBundeslaender} from "../../constants/bundeslaender"
import UserContext from "../../context/UserContext"

const schulArten = [
  {
    value: "1",
    label: "Gymnasium",
  },
  {
    value: "2",
    label: "Oberschule",
  },
  {
    value: "3",
    label: "OSZ",
  },
  {
    value: "4",
    label: "Sonstiges",
  },
]

const buechersystemArten = [
  {
    value: "1",
    label: "Selbst kaufen",
  },
  {
    value: "2",
    label: "Fonds",
  },
  {
    value: "3",
    label: "Bibliothek / kostenlos",
  },
  {
    value: "4",
    label: "Sonstiges",
  },
]

const SchoolHunting = () => {
  const { userData } = useContext(UserContext)
  const adminEmail = userData.Email
  const adminID = userData.SID 

  const [list, setList] = useState([])
  const [mode, setMode] = useState(true) // true = list of all schools, false = add school form
  const [showLoginError, setShowLoginError] = useState("none")
  const [errorMsg, setErrorMsg] = useState("")

  const [bundesland, setBundesland] = useState(JSON.stringify(localStorage.getItem("schoolhunting")).replace(/[""]+/g,"") || "")
  const [stadtListe, setStadtListe] = useState([])
  const [stadt, setStadt] = useState("")
  const [name, setName] = useState("")
  const [emailFirst, setEmailFirst] = useState("")

  const [emailSecond, setEmailSecond] = useState("")
  const [schulart, setSchulart] = useState("")
  const [buechersystem, setBuechersystem] = useState("")
  const [fondsbeitrag, setFondsbeitrag] = useState("")

  const [interesse, setInteresse] = useState("")
  const [kommentar, setKommentar] = useState("")
  const gefundenVon = adminID
  
  useEffect(()=>{
    getCities(bundesland, setStadtListe, alleBundeslaender) 
  }, [bundesland])

  useEffect(()=>{
    setShowLoginError("none")
  }, [mode])

  async function switchBundesland (bl) {
    localStorage.setItem("schoolhunting", bl)
    setBundesland(bl)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const numBL = (alleBundeslaender.indexOf(bundesland) + 1).toString()
      const newContact = await addContact({name, emailFirst, stadt, numBL, emailSecond, schulart, buechersystem, fondsbeitrag, interesse, kommentar, gefundenVon})
      if (newContact.error) {
        setShowLoginError("inherit")
        setErrorMsg(newContact.message)
      }
      else {
        window.location.reload(true)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  useEffect(() => {
    getContacts(setList)
  }, [])

  const columns = [
    { field: "id", headerName: "CID", type: "number", width: 40},
    { field: "Antwort", headerName: "Antwort", width: 100},
    { field: "Name", headerName: "Name", width: 300 },
    { field: "EmailFirst", headerName: "1. Email", width: 200 },
    { field: "EmailSecond", headerName: "2. Email" },
    { field: "Stadt", headerName: "Stadt", width: 150 },
    { field: "Schulart", headerName: "Schulart", width: 80, type: "number" },
    { field: "Buechersystem", headerName: "Büchersystem", width: 80, type: "number"},
    { field: "Fondsbeitrag", headerName: "Fondsbeitrag", width: 80, type: "number" },
    { field: "Interesse", headerName: "Interesse" },
    { field: "Kommentar", headerName: "Kommentar", width: 150 },
  ]

  function filter_bundesland(row) {
    const numBL = (alleBundeslaender.indexOf(bundesland) + 1).toString()
    return row.Bundesland.toString() === numBL
  }
  
  const rows = list.filter(filter_bundesland)

  const headerContent = (
    <Box 
      sx={{
        display: "grid",
        gridTemplateRows: "1fr 1fr",
        gridTemplateColumns: "1fr",
        rowGap: "4vh",
        my: "auto"
      }}
    >
      <Button 
        variant="contained" 
        sx={{
          height: "8vh", 
          width: "80%",
          mx: "auto",
          textTransform: "none",
          boxShadow: "none",
          backgroundColor: "secondary.main",
          color: "white"
        }}
      >
        Schoolhunting
      </Button>
      <Button 
        variant="contained" 
        sx={{
          height: "8vh", 
          width: "80%",
          mx: "auto",
          textTransform: "none",
          boxShadow: "none",
          backgroundColor: "secondary.main",
          color: "white"
        }}
      >
        Lizenzen verwalten
      </Button>
    </Box>
  )

  return (
    <Box sx={{height: "100vh", width: "100vw", backgroundColor: "primary.main"}}>
      <Header content={headerContent} showUserMenu/>
      <Box 
        sx={{
          position: "fixed",
          top: "1vw",
          left: "12vw",
          borderRadius: "38px",
          backgroundColor: "white",
          height: "calc(100vh - 2vw)",
          width: "87vw",
          p: "1vw"
        }}
      >
        <Button onClick={() => {setMode(!mode)}} sx={{backgroundColor: "darkslateblue", color: "white", mt: "3vh"}}>
          {mode ? "Alle anzeigen" : "Hinzufügen"}
        </Button>
        
        <Box sx={{my: "3vh", display:"grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", columnGap:"1vw"}}>
          {
            alleAktuellenBundeslaender.length > 0 && alleAktuellenBundeslaender.map(b => (
                <Button onClick={() => {switchBundesland(b)}} variant="outlined" sx={{backgroundColor: (bundesland.replace(/[""]+/g,"") === b) ? "primary.main" : "white", color: (bundesland.replace(/[""]+/g,"") === b) ? "white" : "primary.main"}}>
                  {b}
                </Button>
            ))
          }
        </Box>
      {mode &&
        <>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                required
                id="name"
                name="name"
                onChange={e => setName(e.target.value)}
                variant="outlined"
                label="Name"
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                required
                id="emailFirst"
                name="emailFirst"
                onChange={e => setEmailFirst(e.target.value)}
                variant="outlined"
                label="1. Email"
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              /> 
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                freeSolo
                id="cities"
                disableClearable
                onChange={e => {
                  setStadt(e.target.innerText)
                }}
                options={stadtListe || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    label="Stadt"
                    required
                    onChange={e => {
                      setStadt(e.target.value)
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                disabled
                required
                id="bundesland"
                name="bundesland"
                InputProps={{
                  readOnly: true,
                }}
                onChange={e => setBundesland(e.target.value)}
                variant="outlined"
                value={bundesland}
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                id="emailSecond"
                name="emailSecond"
                onChange={e => setEmailSecond(e.target.value)}
                variant="outlined"
                label="2. Email"
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                select
                id="schulart"
                name="schulart"
                onChange={e => setSchulart(e.target.value)}
                variant="outlined"
                label="Schulart"
                defaultValue="0"
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              >
                {schulArten.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                select
                margin="normal"
                id="buechersystem"
                name="buechersystem"
                onChange={e => setBuechersystem(e.target.value)}
                defaultValue="0"
                variant="outlined"
                label="Büchersystem"
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              >
                {buechersystemArten.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                id="fondsbeitrag"
                name="fondsbeitrag"
                onChange={e => setFondsbeitrag(e.target.value)}
                variant="outlined"
                label="Fondsbeitrag (Volle €)"
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                id="interesse"
                name="interesse"
                onChange={e => setInteresse(e.target.value)}
                variant="outlined"
                label="Interesse"
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                id="kommentar"
                name="kommentar"
                onChange={e => setKommentar(e.target.value)}
                variant="outlined"
                label="Kommentar"
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="normal"
                id="gefundenVon"
                name="gefundenVon"
                variant="outlined"
                label="Gefunden von"
                value={adminEmail}
                disabled
                sx={{ backgroundColor: "white", borderRadius: "0.3rem", height: "3.5rem"}}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            disabled={!(name.length > 0 && emailFirst.length > 0 && stadt.length > 0 && bundesland.length > 0)}
            sx={{ my: "4vh", color: "white", backgroundColor: "primary.main", width: "20vw" }}
          >
            Hinzufügen
          </Button>
        </Box>
        <LoginError msg={errorMsg || "Error"} visible={showLoginError} />
        </>
      }
      {!mode && 

      <Box sx={{height: "60vh"}}>
        Alle Schulen in {bundesland} ({rows.length}) (Alle BL: {list.length})
        <DataGrid
          rows={rows}
          columns={columns}
        />
      </Box>
      }
      </Box>
    </Box>
  )  
}

export default SchoolHunting
