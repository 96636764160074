import * as React from "react"
import { Link, Button, Toolbar, Paper, Grid, Box } from "@mui/material"
import LogoKlein from "../../components/logo/LogoKlein.png"
import UserMenu from "../../components/UserMenu"

const Header = ({ tabs, setCurrentPage, userData }) => {
  const sections = Object.keys(tabs).map((key) => [key, tabs[key]])
  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={2}>
            <Link href="http://demo.bookay-buecher.de" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none" }}>
              <Box component={Button} display={{ xs: "none", lg: "inherit" }} variant="contained" size="medium">
                Demoversion
              </Box>
            </Link>
          </Grid>
          <Grid item xs={8}>
            <Paper
              onClick={() => setCurrentPage("landing")}
              elevation={0}
              sx={{
                height: "40px",
                cursor: "pointer",
                flex: 1,
                position: "relative",
                color: "#fff",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url(${LogoKlein})`,
              }}>
              .
            </Paper>
          </Grid>
          <Grid item xs={2}>
            {userData?.role === "Schuladmin" ? (
              <UserMenu mainPage />
            ) : (
              <Box
                component={Button}
                display={{ xs: "none", lg: "inherit" }}
                onClick={() => setCurrentPage("login")}
                variant="contained"
                size="medium"
                sx={{ float: "right" }}>
                Login
              </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Toolbar component="nav" variant="dense" sx={{ justifyContent: "space-between", overflowX: "auto" }}>
        {sections.map((section) => (
          <Link
            color="inherit"
            noWrap
            key={section[0]}
            variant="body2"
            onClick={() => setCurrentPage(section[0])}
            sx={{ cursor: "pointer", p: 1, flexShrink: 0 }}>
            {section[0]}
          </Link>
        ))}
      </Toolbar>
    </>
  )
}

export default Header
