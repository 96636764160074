import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Box, AppBar, IconButton, Typography, Divider } from "@mui/material"
import LogoKleinWeiss from "./logo/LogoKleinWeiss.png"
import UserMenu from "./UserMenu"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import UserContext from "../context/UserContext"
import SettingsContext from "../context/SettingsContext"
import LicenceContext from "../context/LicenceContext"

const Header = ({ content, showUserMenu, settings, bookmanagement, isMobile, setCurrentTab }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const { userData, setUserDataExternal } = useContext(UserContext)
  const { setLicenceDataExternal } = useContext(LicenceContext)
  const { setSettingsExternal } = useContext(SettingsContext)

  const navigate = useNavigate()

  const schoolName = userData?.Schulname
  const userEmail = userData?.Email

  const handleLogout = () => {
    setUserDataExternal(null)
    setLicenceDataExternal(null)
    setSettingsExternal(null)
    localStorage.removeItem("user")
    localStorage.removeItem("settings")
    localStorage.removeItem("licence")
    navigate("/")
  }

  return isMobile ? (
    <>
      <IconButton
        onClick={() => setMenuOpen(!menuOpen)}
        sx={{
          left: "1vw",
          top: "1vw",
          zIndex: 1000,
        }}>
        <MenuIcon sx={{ color: "secondary.main" }} />
      </IconButton>
      {menuOpen ? (
        <Box
          sx={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
            zIndex: 900,
            left: 0,
            top: 0,
            pt: 2,
            px: "10%",
            backgroundColor: "primary.main",
          }}>
          <IconButton sx={{ position: "fixed", right: "1vw", top: "1vw", zIndex: 1000 }} onClick={() => setMenuOpen(false)}>
            <CloseIcon sx={{ color: "secondary.main" }} />
          </IconButton>
          <Typography variant="h5" align="center" color="secondary.main">
            {schoolName}
          </Typography>
          <Typography sx={{ my: 2 }} variant="subtitle1" align="center" color="secondary.main">
            {userEmail || ""}
          </Typography>
          <Divider color="secondary.main" sx={{ mb: 8, mt: 4, width: "96%", mx: "2%" }} />
          <Typography
            sx={{ my: 4 }}
            variant="h6"
            align="center"
            color="secondary.main"
            onClick={() => {
              setCurrentTab("Bücherlisten")
              setMenuOpen(false)
            }}>
            Bücherlisten
          </Typography>
          <Typography
            sx={{ my: 4 }}
            variant="h6"
            align="center"
            color="secondary.main"
            onClick={() => {
              setCurrentTab("Meine Bücher")
              setMenuOpen(false)
            }}>
            Meine Bücher
          </Typography>
          <Typography
            sx={{ my: 4 }}
            variant="h6"
            align="center"
            color="secondary.main"
            onClick={() => {
              setCurrentTab("Chat")
              setMenuOpen(false)
            }}>
            Chat
          </Typography>
          <Typography
            sx={{ my: 4 }}
            variant="h6"
            align="center"
            color="secondary.main"
            onClick={() => {
              setCurrentTab("Verlauf")
              setMenuOpen(false)
            }}>
            Verlauf
          </Typography>
          <Divider color="secondary.main" sx={{ width: "96%", mx: "2%", mt: 8, mb: 4 }} />
          <Typography
            component="a"
            href="https://bookay-buecher.de"
            sx={{ my: 1, textDecoration: "none", width: "100%", display: "inline-block" }}
            variant="subtitle1"
            align="center"
            color="secondary.main">
            Startseite
          </Typography>
          <Typography
            component="a"
            href="https://bookay-buecher.de/start?page=impressum"
            sx={{ my: 1, textDecoration: "none", width: "100%", display: "inline-block" }}
            variant="subtitle1"
            align="center"
            color="secondary.main">
            Impressum
          </Typography>
          <Typography
            sx={{ my: 1 }}
            variant="subtitle1"
            align="center"
            color="secondary.main"
            onClick={() => {
              setMenuOpen(false)
              handleLogout()
            }}>
            Logout
          </Typography>
          <Typography sx={{ mt: 6 }} variant="subtitle2" align="center" color="secondary.main">
            made in berlin
          </Typography>
          <Typography variant="subtitle2" align="center" color="secondary.main">
            v3.2 (Phys.)
          </Typography>
          <Typography align="center" sx={{ mt: 2 }}>
            <img style={{ height: "auto", width: "30vw" }} src={LogoKleinWeiss} alt="logo" />
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </>
  ) : (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        width: "12vw",
        maxWidth: "20vw",
        zIndex: 100,
      }}>
      <Box flexGrow={0}>
        <AppBar elevation={0} position="sticky" sx={{ backgroundColor: bookmanagement ? "bmprimary.main" : "initial" }}>
          <Box sx={{ display: "grid", gridTemplateRows: "16vh auto 10vh", gridTemplateColumns: "1fr", rowGap: "auto", height: "100vh" }}>
            <Box sx={{ height: "16vh", textAlign: "center" }}>
              <UserMenu showName={showUserMenu} settings={settings} bookmanagement={bookmanagement} handleLogout={handleLogout} />
            </Box>
            <Box sx={{ height: "74vh", textAlign: "center", display: "grid" }}>
              <Box sx={{ my: "auto" }}>{content || <></>}</Box>
            </Box>
            <Box sx={{ height: "10vh", textAlign: "center" }}>
              <img style={{ height: "auto", width: "10vw" }} src={LogoKleinWeiss} alt="logo" />
            </Box>
          </Box>
        </AppBar>
      </Box>
    </Box>
  )
}

export default Header
