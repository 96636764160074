/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { Typography, Grid, Stack, Box, Divider, Input, IconButton, InputAdornment, Button } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import SendIcon from "@mui/icons-material/Send"
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone"
import UserContext from "../../context/UserContext"
import { getAllChats, sendMsg } from "../../fetch/chat"
import { cancelMatchedPosition, finishMatchedPosition } from "../../fetch/trading"
import ChatDialog from "./ChatDialog"
import LogoKlein from "../../components/logo/LogoKlein.png"
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone"
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone"

const Chat = ({ goToVerlauf, isMobile, setSnackbarMsg, setSnackbarOpen }) => {
  const { userData } = useContext(UserContext)
  const sid = userData.SID
  const [allChats, setAllChats] = useState([])
  const [currentChat, setCurrentChat] = useState(null)
  const [currentChatHistory, setCurrentChatHistory] = useState(null)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showFertigDialog, setShowFertigDialog] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [msg, setMsg] = useState("")

  // noch überlegen ------------------------------
  const shouldPeriodicallyFetch = false
  // ---------------------------------------------

  const handleFinish = (LID, preis) => {
    const finish = finishMatchedPosition({ LID, preis })
    goToVerlauf()
    setSnackbarMsg("Abgeschlossen!")
    setSnackbarOpen(true)
    if (finish.error) {
      console.log(finish.error)
    }
  }

  const handleCancelPosition = (LID) => {
    const cancel = cancelMatchedPosition({ LID })
    if (cancel.error) {
      console.log(cancel.error)
    } else {
      setSnackbarMsg("Buch abgebrochen")
      setSnackbarOpen(true)
    }
  }

  const cancelDialog = () => {
    setShowFertigDialog(false)
    setShowCancelDialog(false)
  }

  useEffect(() => {
    getAllChats(sid, setAllChats)

    if (shouldPeriodicallyFetch) {
      const id = setInterval(() => {
        getAllChats(sid, setAllChats)
      }, 10000)
      return () => clearInterval(id)
    }
  }, [])

  useEffect(() => {
    // if the interval fetches all chats, the current chat should be updated too
    if (shouldPeriodicallyFetch) {
      if (currentChat) {
        const updatedChat = allChats.filter((c) => c.CID === currentChat.CID)
        setCurrentChat(updatedChat[0])
      }
    }
  }, [allChats])

  useEffect(() => {
    getAllChats(sid, setAllChats)
    if (currentChat) {
      const currentRole = allChats.filter((c) => c.CID === currentChat.CID)
      const { Verlauf } = currentChat
      const parsed = JSON.parse(Verlauf)
      const withRole = { ...parsed, role: currentRole[0].Aktion }
      setCurrentChatHistory(withRole)
    }
  }, [currentChat])

  const handleSend = () => {
    setTimeout(() => {
      setIsTyping(false)
    }, 500)
    if (msg.length > 0 && msg.trim() !== "") {
      const m = new Date()
      const now =
        m.getUTCFullYear() +
        "/" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) +
        "/" +
        ("0" + m.getUTCDate()).slice(-2) +
        " " +
        ("0" + m.getUTCHours()).slice(-2) +
        ":" +
        ("0" + m.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + m.getUTCSeconds()).slice(-2)
      const { Verlauf } = currentChat
      const parsed = JSON.parse(Verlauf)
      let newVerlauf = parsed.chat
      newVerlauf.push({ msg: msg, datetime: now, sender: currentChatHistory.role.toString() })
      const updatedChat = JSON.stringify({ chat: newVerlauf })
      setCurrentChat({ ...currentChat, Verlauf: updatedChat })
      setMsg("")
      sendMsg(currentChat.CID, updatedChat)
    }
  }

  let scroll = document.getElementById("chat")
  if (scroll) {
    scroll.scrollTop = scroll.scrollHeight
  }

  let scrollPage = document.getElementById("pageScrollID")
  if (scrollPage) {
    scrollPage.scrollTop = scrollPage.scrollHeight
  }

  const isTypingViewPort = useMediaQuery("(max-height:500px)")

  useEffect(() => {
    setIsTyping(isTypingViewPort)
  }, [isTypingViewPort])

  return isMobile ? (
    <>
      <Typography variant="h5" align="center" sx={{ color: "secondary.main", ml: "1vw", pt: "1vw", width: "98%", mb: 2, height: "5vh" }}>
        Chat
      </Typography>
      <Grid
        container
        justifyContent="space-evenly"
        sx={{
          width: "100%",
          height: "90vh",
          pb: "1vw",
          pr: "1vw",
          borderTop: "1px solid",
          borderTopColor: "primary.main",
        }}>
        <Grid item xs={currentChat ? 1.9 : 9.9}>
          <Stack sx={{ overflow: "auto" }}>
            {allChats.length > 0 &&
              allChats.map((c, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "default",
                    height: "6vh",
                    lineHeight: "6vh",
                    px: "5%",
                    my: "1vh",
                    borderTopRightRadius: "38px",
                    borderBottomRightRadius: "38px",
                    color: c.Status === "matched" ? "inherit" : "grey",
                    backgroundColor: c?.CID === currentChat?.CID ? "#ebebeb" : "inherit",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    if (currentChat) {
                      setCurrentChat(null)
                    } else {
                      setCurrentChat(c)
                    }
                  }}>
                  {c.Status === "matched" ? (
                    <ChatTwoToneIcon sx={{ color: "primary.main", verticalAlign: "middle", mr: currentChat ? 0 : 1, ml: !currentChat ? 0 : 1 }} />
                  ) : (
                    <CheckBoxTwoToneIcon sx={{ color: "inherit", verticalAlign: "middle", mr: currentChat ? 0 : 1, ml: !currentChat ? 0 : 1 }} />
                  )}
                  {currentChat ? "" : c.Is_Bundle ? "Bücherpaket" : c.Titel}
                </Box>
              ))}
          </Stack>
        </Grid>
        <Grid item xs={0.2}>
          <Divider orientation="vertical" sx={{ borderColor: "primary.main" }} />
        </Grid>
        <Grid item xs={currentChat ? 9.9 : 1.9}>
          <Box sx={{ p: "2vh" }}>
            {currentChat && (
              <>
                <Grid container justifyContent="space-between" sx={{ height: "10vh" }}>
                  <Grid item xs={1.5}>
                    <BookTwoToneIcon fontSize="large" sx={{ color: currentChat.Status === "matched" ? "primary.main" : "inherit" }} />
                  </Grid>
                  <Grid item xs={10.5}>
                    <Typography variant="h5" sx={{ color: currentChat.Status === "matched" ? "inherit" : "grey" }}>
                      {currentChat.Titel} {currentChat.Status === "matched" ? "" : "• Fertig"}
                    </Typography>
                  </Grid>
                  {currentChat.Status === "matched" && (
                    <Grid item container justifyContent="flex-start" xs={12} spacing={2} sx={{ pl: 1 }}>
                      <Grid item xs={5}>
                        <Button onClick={() => setShowFertigDialog(true)} fullWidth variant="contained" disableElevation>
                          Fertig
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button onClick={() => setShowCancelDialog(true)} fullWidth variant="outlined" sx={{ color: "red", borderColor: "red" }}>
                          Abbrechen
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {currentChat.Status === "fertig" && currentChat.VerhandelterPreis !== 0 && (
                    <Grid item xs={12}>
                      <Typography variant="h5" align="right" sx={{ color: "grey" }}>
                        {currentChat?.VerhandelterPreis ? currentChat?.VerhandelterPreis + " €" : ""}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ mt: "2vh" }} />
                <Stack
                  id="chat"
                  sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: currentChat.Status === "matched" ? "69vh" : "69vh",
                    mt: "1vh",
                    pr: "1vw",
                    pb: isTyping ? "10vh" : "initial",
                  }}>
                  {currentChatHistory &&
                    currentChatHistory.chat.map((c, index) => (
                      <Typography
                        key={index}
                        align={currentChatHistory.role.toString() === c.sender.toString() ? "right" : "left"}
                        sx={{
                          wordWrap: "break-word",
                          border: "1px solid gray",
                          borderRadius: "20px",
                          borderBottomLeftRadius: currentChatHistory.role.toString() === c.sender.toString() ? "20px" : "0",
                          borderBottomRightRadius: currentChatHistory.role.toString() === c.sender.toString() ? "0" : "20px",
                          height: "auto",
                          width: "auto",
                          maxWidth: "60%",
                          p: "1vh",
                          my: "1vh",
                          ml: currentChatHistory.role.toString() === c.sender.toString() ? "auto" : "0",
                          mr: currentChatHistory.role.toString() === c.sender.toString() ? "0" : "auto",
                          backgroundColor: currentChatHistory.role.toString() === c.sender.toString() ? "primary.main" : "#74A877",
                        }}>
                        {c.msg}
                      </Typography>
                    ))}
                </Stack>
                <Box sx={{ position: isTyping ? "fixed" : "inherit", bottom: isTyping ? "10vh" : "undefined", mb: isTyping ? "undefined" : "10svh" }}>
                  <Input
                    sx={{
                      border: "2px solid",
                      borderColor: currentChat.Status === "fertig" || msg.length <= 0 || msg.trim() === "" ? "grey" : "primary.main",
                      borderRadius: "20px",
                      height: "5vh",
                      mt: "1vh",
                      p: "1vh",
                      visibility: currentChat.Status === "fertig" ? "hidden" : "visible",
                      backgroundColor: "white",
                    }}
                    fullWidth
                    id="msg"
                    name="msg"
                    onChange={(e) => setMsg(e.target.value)}
                    onClick={() => setIsTyping(true)}
                    value={msg}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSend()
                      }
                    }}
                    variant="standard"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            handleSend()
                          }}>
                          <SendIcon
                            sx={{ color: currentChat.Status === "fertig" || msg.length <= 0 || msg.trim() === "" ? "grey" : "primary.main" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                    disableUnderline
                    disabled={currentChat.Status === "fertig"}
                  />
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {(showFertigDialog || showCancelDialog) && (
        <ChatDialog
          showFertigDialog={showFertigDialog}
          showCancelDialog={showCancelDialog}
          LID={currentChat.LID}
          Aktion={currentChat.Aktion}
          handleCancelPosition={handleCancelPosition}
          cancelDialog={cancelDialog}
          handleFinish={handleFinish}
        />
      )}
    </>
  ) : (
    <>
      <Grid
        container
        justifyContent="space-evenly"
        sx={{
          width: "100%",
          height: "100%",
          pb: "1vw",
          pr: "1vw",
          border: "2px solid",
          borderColor: "primary.main",
          borderRadius: "38px",
        }}>
        <Grid item xs={2.9}>
          <Box
            sx={{
              cursor: "default",
              height: "8vh",
              px: "5%",
              borderTopLeftRadius: "38px",
              overflow: "hidden",
            }}>
            <Typography variant="h5" sx={{ lineHeight: "8vh", color: "secondary.main" }}>
              Chat
            </Typography>
          </Box>
          <Stack sx={{ overflow: "auto" }}>
            <Divider flexItem />
            {allChats.length > 0 &&
              allChats.map((c, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "default",
                    height: "6vh",
                    lineHeight: "6vh",
                    px: "5%",
                    my: "1vh",
                    borderTopRightRadius: "38px",
                    borderBottomRightRadius: "38px",
                    color: c.Status === "matched" ? "inherit" : "grey",
                    // textDecoration: c?.CID === currentChat?.CID ? "underline" : "inherit",
                    backgroundColor: c?.CID === currentChat?.CID ? "#ebebeb" : "inherit",
                    overflow: "hidden",
                  }}
                  onClick={() => setCurrentChat(c)}>
                  {c.Status === "matched" ? (
                    <ChatTwoToneIcon sx={{ color: "primary.main", verticalAlign: "middle", mr: 1 }} />
                  ) : (
                    <CheckBoxTwoToneIcon sx={{ color: "inherit", verticalAlign: "middle", mr: 1 }} />
                  )}
                  {c.Is_Bundle ? "Bücherpaket" : c.Titel}
                </Box>
              ))}
          </Stack>
        </Grid>
        <Grid item xs={0.2}>
          <Divider orientation="vertical" sx={{ borderColor: "primary.main" }} />
        </Grid>
        <Grid item xs={8.9}>
          <Box sx={{ p: "2vh" }}>
            {!currentChat && (
              <Box sx={{ mx: "auto", mt: "34vh", color: "primary.main" }}>
                <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
                  <img src={LogoKlein} height="50px" alt="Bookay" />
                  Chat
                </Typography>
              </Box>
            )}
            {currentChat && (
              <>
                <Grid container justifyContent="space-between" sx={{ height: "8vh", display: "inline-flex" }}>
                  <Grid item xs={0.5}>
                    <BookTwoToneIcon fontSize="large" sx={{ color: currentChat.Status === "matched" ? "primary.main" : "inherit " }} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h5" sx={{ cursor: "default", color: currentChat.Status === "matched" ? "inherit" : "grey" }}>
                      {currentChat.Titel} {currentChat.Status === "matched" ? "" : "• Fertig"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4.5}>
                    {currentChat.Status === "matched" && (
                      <Box sx={{ float: "right" }}>
                        <Button
                          onClick={() => setShowFertigDialog(true)}
                          variant="contained"
                          sx={{ width: "10vw", mr: "2vw", "&:hover": { backgroundColor: "#b3ffb0" } }}
                          disableElevation>
                          ✅ Fertig
                        </Button>
                        <Button
                          onClick={() => setShowCancelDialog(true)}
                          variant="outlined"
                          sx={{ width: "10vw", color: "red", borderColor: "red", "&:hover": { borderColor: "red", backgroundColor: "salmon" } }}>
                          ❌ Abbrechen
                        </Button>
                      </Box>
                    )}
                    {currentChat.Status === "fertig" && currentChat.VerhandelterPreis !== 0 && (
                      <Typography variant="h5" align="right" sx={{ color: "grey" }}>
                        {currentChat?.VerhandelterPreis ? currentChat?.VerhandelterPreis + " €" : ""}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider sx={{ mt: "-2vh" }} />
                <Stack
                  id="chat"
                  sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: currentChat.Status === "fertig" ? "81vh" : "75vh",
                    mt: "1vh",
                    pr: "1vw",
                  }}>
                  {currentChatHistory &&
                    currentChatHistory.chat.map((c, index) => (
                      <Typography
                        key={index}
                        align={currentChatHistory.role.toString() === c.sender.toString() ? "right" : "left"}
                        sx={{
                          wordWrap: "break-word",
                          border: "1px solid gray",
                          borderRadius: "20px",
                          borderBottomLeftRadius: currentChatHistory.role.toString() === c.sender.toString() ? "20px" : "0",
                          borderBottomRightRadius: currentChatHistory.role.toString() === c.sender.toString() ? "0" : "20px",
                          height: "auto",
                          width: "auto",
                          maxWidth: "60%",
                          p: "1vh",
                          my: "1vh",
                          ml: currentChatHistory.role.toString() === c.sender.toString() ? "auto" : "0",
                          mr: currentChatHistory.role.toString() === c.sender.toString() ? "0" : "auto",
                          backgroundColor: currentChatHistory.role.toString() === c.sender.toString() ? "primary.main" : "#74A877",
                        }}>
                        {c.msg}
                      </Typography>
                    ))}
                </Stack>
                <Input
                  autoFocus
                  sx={{
                    border: "2px solid",
                    borderColor: currentChat.Status === "fertig" || msg.length <= 0 || msg.trim() === "" ? "grey" : "primary.main",
                    borderRadius: "20px",
                    height: "5vh",
                    mt: "1vh",
                    p: "1vh",
                    visibility: currentChat.Status === "fertig" ? "hidden" : "visible",
                  }}
                  fullWidth
                  id="msg"
                  name="msg"
                  onChange={(e) => setMsg(e.target.value)}
                  value={msg}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSend()
                    }
                  }}
                  variant="standard"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleSend()}>
                        <SendIcon sx={{ color: currentChat.Status === "fertig" || msg.length <= 0 || msg.trim() === "" ? "grey" : "primary.main" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  disableUnderline
                  disabled={currentChat.Status === "fertig"}
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {(showFertigDialog || showCancelDialog) && (
        <ChatDialog
          showFertigDialog={showFertigDialog}
          showCancelDialog={showCancelDialog}
          LID={currentChat.LID}
          Aktion={currentChat.Aktion}
          handleCancelPosition={handleCancelPosition}
          cancelDialog={cancelDialog}
          handleFinish={handleFinish}
        />
      )}
    </>
  )
}

export default Chat
