import {fetchHeaders} from "./fetchHeaders"
import { BACKEND_BUCHVERWALTUNG_DELETEBOOK, 
    BACKEND_BUCHVERWALTUNG_ALL_ID, 
    BACKEND_BUCHVERWALTUNG_ADDBOOK, 
    BACKEND_BUCHVERWALTUNG_UPDATEBOOK 
} from "../constants/routes"

export const getAlleBuecher = (id, set) => {
    const withParam = BACKEND_BUCHVERWALTUNG_ALL_ID + id
    return fetch(withParam, {
        method: "GET",
        headers: fetchHeaders
    })
    .then(res => {
        return res.json()
    })
    .then(data => {
        const result = Object.keys(data).map((key) => data[key]) // converts the data object to an array
        set(result)
    })
}

export const buchHinzufuegen = (data) => {
    return fetch(BACKEND_BUCHVERWALTUNG_ADDBOOK, {
        method: "POST",
        headers: fetchHeaders,
        body: JSON.stringify(data),
    })
    .then(res => {
        return res.json()
    })
}

export const buchLoeschen = (bid) => {
    return fetch(BACKEND_BUCHVERWALTUNG_DELETEBOOK, {
        method: "POST",
        headers: fetchHeaders,
        body: JSON.stringify({bid}),
    })
    .then(res => {
        return res.json()
    })
}

export const buchBearbeiten = (data) => {
    return fetch(BACKEND_BUCHVERWALTUNG_UPDATEBOOK, {
        method: "POST",
        headers: fetchHeaders,
        body: JSON.stringify(data),
    })
    .then(res => {
        return res.json()
    })
}