import * as React from "react"
import { Grid, Typography, Stack, Paper, Link } from "@mui/material"
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"
import LockIcon from "@mui/icons-material/Lock"
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone"

const Sidebar = ({ setCurrentPage }) => {
  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={0} sx={{ p: 2, bgcolor: "grey.200", cursor: "pointer" }} onClick={() => setCurrentPage("Lizenzen und Module")}>
        <Typography variant="h6" gutterBottom>
          Individuelle Anpassungen
        </Typography>
        <Typography>
          Hat Ihre Schule Besonderheiten im Büchersystem? Haben Sie beispielsweise eine übergreifende Oberstufe? <b>Hier</b> bieten wir individuelle
          Module an.
        </Typography>
      </Paper>
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Unsere Partner
      </Typography>
      {[
        { title: "Hans-und-Hilde-Coppi-Gymnasium" },
        { title: "George-Orwell-Oberschule" },
        { title: "Paul-und-Charlotte-Kniese-Schule" },
        { title: "Schule am Tierpark" },
      ].map((archive) => (
        <Link display="inline-flex" onClick={() => setCurrentPage("Partnerschulen")} variant="body1" href={undefined} key={archive.title}>
          <SchoolTwoToneIcon /> {archive.title}
        </Link>
      ))}
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Impressum & Kontakt
      </Typography>
      {[
        { name: "Kontakt", icon: ContactPhoneIcon, id: "Kontakt" },
        { name: "Datenschutzerklärung", icon: LockIcon, id: "datenschutz" },
        { name: "Impressum", icon: FormatQuoteIcon, id: "impressum" },
      ].map((network) => (
        <Link display="block" variant="body1" href="#" onClick={() => setCurrentPage(network.id)} key={network.name} sx={{ mb: 0.5 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <network.icon />
            <span>{network.name}</span>
          </Stack>
        </Link>
      ))}
    </Grid>
  )
}

export default Sidebar
