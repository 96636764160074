import React from "react"
import { useState } from "react"
import SettingsContext from "./SettingsContext"

const initialSettings = () => {
  return {"defaultTradingPage":"Bücherlisten"}
}

const UserProvider = (props) => {
  const [settings, setSettings] = useState(initialSettings())

  const setSettingsExternal = (data) => {
    localStorage.setItem("settings", JSON.stringify(data))
    setSettings(data)
  }

  return <SettingsContext.Provider value={{ settings, setSettingsExternal }}>{props.children}</SettingsContext.Provider>
}

export default UserProvider
