import React, { useState } from "react"
import { Box, Button, Grid } from "@mui/material"
import Header from "../../components/Header"
import Bookmanagement from "./Bookmanagement"
import Lizenzen from "./Lizenzen"

const TabList = [
  {
    name: "Bücherlisten",
  },
  {
    name: "Lizenzen",
  },
]

const Tabs = {
  Bücherlisten: <Bookmanagement />,
  Lizenzen: <Lizenzen />,
}

const Schuladmin = () => {
  const [currentTab, setCurrentTab] = useState("Bücherlisten")

  const headerContent = (
    <Grid container spacing="4vh">
      {TabList.map((t) => (
        <Grid container item xs={12} columns={10} key={t.name}>
          <Grid item xs={1}>
            <Box
              sx={{
                height: "8vh",
                width: "100%",
                visibility: "hidden",
              }}></Box>
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              onClick={() => setCurrentTab(t.name)}
              sx={{
                height: "8vh",
                width: "100%",
                // mx: "10%",
                textTransform: "none",
                boxShadow: "none",
                backgroundColor: currentTab === t.name ? "white" : "bmsecondary.main",
                color: currentTab === t.name ? "bmsecondary.main" : "white",
                "&:hover": { backgroundColor: "inherit" },
              }}>
              {t.name}
            </Button>
          </Grid>

          <Grid item xs={1}>
            <Box
              sx={{
                backgroundColor: "white",
                height: "8vh",
                width: "100%",
                visibility: currentTab === t.name ? "visible" : "hidden",
              }}></Box>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )

  return (
    <Box sx={{ height: "100vh", width: "100vw", backgroundColor: "bmprimary.main" }}>
      <Header content={headerContent} showUserMenu bookmanagement />
      {Tabs[currentTab] || Tabs.Bookmanagement}
    </Box>
  )
}

export default Schuladmin
