import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Container, Paper, Stepper, Step, StepLabel, Button, Typography } from "@mui/material"
import LoginError from "../../../components/LoginError"

import { F_TRADING } from "../../../constants/routes"
import Schuldaten from "./Schuldaten"
import LizenzAuswahl from "./LizenzAuswahl"
import Review from "./Review"

import { makeNeukunde, askForMore } from "../../../fetch/checkout"

const steps = ["Schuldaten", "Lizenzen wählen", "Übersicht"]

const cleanInitialLI = {
  lizenzen: {
    Test: false,
    Basis: false,
    Progressiv: false,
    Full: false,
    "Personalisierte-Lizenz": false,
  },
  module: {
    Buecherlisten: false,
    "Eigene-Faecher": false,
    "QR-Codes": false,
    "Fast-Track": false,
    "Personalisiertes-Modul": false,
  },
}

const initialLI = {
  lizenzen: {
    Test: false,
    Basis: true, // ausschalten
    Progressiv: false,
    Full: false,
    "Personalisierte-Lizenz": false,
  },
  module: {
    Buecherlisten: false,
    "Eigene-Faecher": false,
    "QR-Codes": false,
    "Fast-Track": false,
    "Personalisiertes-Modul": false,
  },
}

const Checkout = ({ setCurrentPage, userData }) => {
  const navigate = useNavigate()
  const initialSDI = {
    schulname: userData?.Schulname || "",
    email: userData?.Email || "",
    bundesland: userData?.Bundesland || "",
    stadt: userData?.Stadt || "",
    schulart: userData?.Schulart || "",
    kontakt: userData?.Kontaktperson || "",
  }

  const [activeStep, setActiveStep] = useState(0)
  const [schuldatenInfo, setSchuldatenInfo] = useState(initialSDI)
  const [lizenzenInfo, setLizenzenInfo] = useState(initialLI)
  const [errorMsg, setErrorMsg] = useState("")
  const [showLoginError, setShowLoginError] = useState("hidden")
  const [validSchoolData, setValidSchoolData] = useState(false)

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Schuldaten
            userData={userData}
            setValidSchoolData={setValidSchoolData}
            schuldatenInfo={schuldatenInfo}
            setSchuldatenInfo={setSchuldatenInfo}
          />
        )
      case 1:
        return <LizenzAuswahl cleanInitialLI={cleanInitialLI} lizenzenInfo={lizenzenInfo} setLizenzenInfo={setLizenzenInfo} />
      case 2:
        return <Review schuldatenInfo={schuldatenInfo} lizenzenInfo={lizenzenInfo} />
      default:
        throw new Error("Unknown step")
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleLoginAccess = () => {
    if (userData?.role === "Schuladmin") {
      navigate(F_TRADING)
    } else {
      setCurrentPage("login")
    }
  }

  const handleBuy = async (event) => {
    event.preventDefault()
    try {
      const { schulname, email, bundesland, stadt, schulart, kontakt } = schuldatenInfo
      const { lizenzen, module } = lizenzenInfo
      let lizCopy = Object.keys(lizenzen).map((key) => [key, lizenzen[key]])
      let modCopy = Object.keys(module).map((key) => [key, module[key]])
      const liz = lizCopy.filter((l) => l[1])
      const mod = modCopy.filter((l) => l[1])
      const modOnlyNames = mod.map((m) => m[0])

      const freshData = { schulname, email, bundesland, stadt, lizenzName: liz[0][0], modulNamen: modOnlyNames, schulart, kontakt }
      const neukunde = userData?.role === "Schuladmin" ? await askForMore(freshData) : await makeNeukunde(freshData)
      setActiveStep(activeStep + 1)
      if (neukunde.error) {
        setShowLoginError("inherit")
        setErrorMsg(neukunde.message)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center">
          Lizenzen und Module anlegen
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          errorMsg ? (
            <>
              <Typography variant="h5" gutterBottom>
                Es ist ein Fehler bei der Verarbeitung aufgetreten...
              </Typography>
              <LoginError msg={errorMsg} visible={showLoginError} />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button fullWidth variant="contained" onClick={() => setCurrentPage("Kontakt")} sx={{ mt: 3, ml: 1 }}>
                  Lizenzen und Module per Email anfragen
                </Button>
              </Box>
            </>
          ) : userData?.role === "Schuladmin" ? (
            <>
              <Typography variant="h5" gutterBottom>
                Vielen Dank für Ihre unverbindliche Anfrage.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Der Eingang Ihrer Anfrage ist bestätigt. Wir werden uns in den kommenden Tagen bezüglich der angefragten Lizenzen und Module per Email
                zurückmelden. <br /> Wenn Sie dringende Unterstützung oder Fragen haben, können Sie uns unter dmitrij.tjumenzew@bookay-buecher.de oder WhatsApp (s.
                Impressum) erreichen.
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button fullWidth variant="contained" onClick={handleLoginAccess} sx={{ mt: 3, ml: 1 }}>
                  Zur Web App
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h5" gutterBottom>
                Vielen Dank für Ihre unverbindliche Anfrage.
              </Typography>
              <Typography variant="subtitle1" color="primary.main" gutterBottom>
                Die App ist für Sie nun freigeschaltet!
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Sie erhalten in Kürze eine Bestätigung per Email mit Ihren persönlichen Login-Daten. Wir werden Sie in den nächsten Tagen bezüglich
                Anleitungen und weiteren Informationen per Email kontaktieren. <br /> Falls Sie bis dahin Fragen haben sollten, stehen wir Ihnen unter
                dmitrij.tjumenzew@bookay-buecher.de jederzeit zur Verfügung.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Wenn Sie "Individuelle Lizenz" oder "Individuelles Modul" ausgewählt haben, werden wir uns mit Ihnen bezüglich der individuellen
                Anpassungen in Verbindung setzen.
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button fullWidth variant="contained" onClick={handleLoginAccess} sx={{ mt: 3, ml: 1 }}>
                  Zum Login
                </Button>
              </Box>
            </>
          )
        ) : (
          <>
            {getStepContent(activeStep)}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Zurück
                </Button>
              )}
              <Button
                variant="contained"
                disabled={!validSchoolData}
                onClick={validSchoolData ? (activeStep === steps.length - 1 ? handleBuy : handleNext) : () => {}}
                sx={{ mt: 3, ml: 1 }}>
                {activeStep === steps.length - 1 ? "Unverbindlich anfragen" : "Weiter"}
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Container>
  )
}

export default Checkout
