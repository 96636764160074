import { fetchHeaders } from "./fetchHeaders"
import { BACKEND_CONTACTS_ALLCONTACTS, BACKEND_CONTACTS_ADDCONTACT, BACKEND_CONTACTS_ALLCITIES_BL } from "../constants/routes"

export const getContacts = (setAllContacts) => {
  return fetch(BACKEND_CONTACTS_ALLCONTACTS, {
    method: "GET",
    headers: fetchHeaders,
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      let result = Object.keys(data).map((key) => data[key]); // converts the data object to an array
      setAllContacts(result)
    })
}

export const getCities = (bl, setCities, alleBundeslaender) => {
  const numBL = (bl && alleBundeslaender) ? (alleBundeslaender.indexOf(bl) + 1).toString() : false
  if(numBL) {
    const urlwithget = BACKEND_CONTACTS_ALLCITIES_BL + numBL
    return fetch(urlwithget, {
      method: "GET",
      headers: fetchHeaders,
    }).then(res => {
      return res.json()
    }).then(data => {
      let result = Object.values(data) // converts the data object to an array
      setCities(result)
    })
  }
  else {
    return []
  }
}

export const addContact = contactData => {
  return fetch(BACKEND_CONTACTS_ADDCONTACT, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify(contactData),
  }).then(data => data.json())
}