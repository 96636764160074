import React from "react"
import { Card, Grid, Typography } from "@mui/material"
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone"

const HistoryItem = ({ details, setCurrentHP }) => {
  const { Titel, Klasse, Is_Bundle, VerhandelterPreis } = details
  return (
    <Card
      variant="outlined"
      onClick={() => setCurrentHP(details)}
      sx={{
        width: "90%",
        mx: "5%",
        my: "1vh",
        height: "auto",
        px: "1vw",
        cursor: "pointer",
        "&:hover": { backgroundColor: "lightgray" },
      }}>
      <Grid container alignItems="flex-end">
        <Grid item xs={2}>
          <CheckBoxTwoToneIcon sx={{ verticalAlign: "bottom", mt: "10%" }} />
        </Grid>
        {VerhandelterPreis ? (
          <Grid item xs={10}>
            <Typography>{VerhandelterPreis} €</Typography>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <Typography>{Is_Bundle ? `Bücherpaket Klasse ${Klasse}` : Titel}</Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default HistoryItem
