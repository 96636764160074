import React, { Fragment } from "react"
import { Grid, Typography, Divider } from "@mui/material"

const Main = ({ posts, title, fullSize }) => {
  return (
    <Grid
      item
      xs={12}
      md={fullSize ? 12 : 8}
      sx={{
        py: 3,
      }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      {posts.map((post, index) => (
        <Fragment key={index}>{post}</Fragment>
      ))}
    </Grid>
  )
}

export default Main
