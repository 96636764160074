import { fetchHeaders } from "./fetchHeaders"
import { BACKEND_CHAT_GETCHATS, BACKEND_CHAT_SENDMSG } from "../constants/routes"

export const getAllChats = (sid, setAll) => {
  return fetch(BACKEND_CHAT_GETCHATS, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify({ sid }),
  })
    .then((response) => {
      return response.json()
    })
    .then((res) => {
      let result = Object.keys(res).map((key) => res[key]) // converts the data object to an array
      setAll(result)
    })
}

export const sendMsg = (cid, msg) => {
  return fetch(BACKEND_CHAT_SENDMSG, {
    method: "POST",
    headers: fetchHeaders,
    body: JSON.stringify({ cid, msg }),
  })
}
