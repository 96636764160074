import React, { useState } from "react"
import { Box, TextField, Button, Typography, Grid } from "@mui/material"
import LoginError from "../../../components/LoginError"
import { sendNewPW, saveNewPW } from "../../../fetch/login"
import { savePW } from "../../../fetch/checkout"

const sha256 = require("js-sha256")

const hashing = (password) => {
  return sha256(password)
}

const ForgotPassword = ({ isSchoolForgotPW, setCurrentPage }) => {
  const [errorMsg, setErrorMsg] = useState("")
  const [showLoginError, setShowLoginError] = useState("none")
  const [email, setEmail] = useState("")
  const [newPW, setNewPW] = useState("")
  const [disableForm, setDisableForm] = useState(false)
  let code = false

  const c = localStorage.getItem("c")
  if (c) {
    code = JSON.parse(c)
  }

  const handleSendPW = async (event) => {
    event.preventDefault()
    try {
      const sendNew = await sendNewPW({ email })

      if (sendNew.error) {
        setErrorMsg(sendNew.message)
        setShowLoginError("inherit")
      } else {
        setErrorMsg(isSchoolForgotPW ? "Passwort wurde gespeichert." : "Email versendet!")
        setShowLoginError("inherit")
        setEmail("")
        setDisableForm(true)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const handleResetPW = async (event) => {
    event.preventDefault()
    try {
      const hashed = hashing(newPW)
      const saveNew = isSchoolForgotPW ? await savePW({ pw: hashed, c: code }) : await saveNewPW({ newpw: hashed, c: code })
      if (saveNew.error) {
        setErrorMsg(saveNew.message)
        setShowLoginError("inherit")
      } else {
        setCurrentPage("login")
        localStorage.removeItem("c")
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <Grid container>
      <Grid item xs={1} md={4}>
        <Box></Box>
      </Grid>
      <Grid item xs={10} md={4}>
        <Box
          component="form"
          onSubmit={code ? handleResetPW : handleSendPW}
          noValidate
          sx={{
            mx: "auto",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "primary.main",
            borderRadius: "12px",
            p: "5vh",
            width: "100%",
            mt: "5vh",
          }}>
          <Typography variant="body2" sx={{ color: "black" }}>
            {isSchoolForgotPW ? "Passwort festlegen" : code ? "Neues Passwort" : "Passwort zurücksetzen"}
          </Typography>
          <TextField
            margin="normal"
            disabled={disableForm}
            required
            fullWidth
            type={isSchoolForgotPW ? "password" : code ? "password" : "text"}
            id={code ? "newPW" : "sendPW"}
            name={code ? "newPW" : "sendPW"}
            autoFocus
            onChange={(e) => (code ? setNewPW(e.target.value) : setEmail(e.target.value))}
            variant="outlined"
            label={isSchoolForgotPW ? "Passwort festlegen" : code ? "Passwort" : "Nutzername / Email"}
            sx={{ backgroundColor: "white", height: "3.5rem", borderRadius: "0.3rem" }}
          />
          <Button
            type="submit"
            fullWidth
            disabled={(code ? !newPW.length : !email.length) || disableForm}
            sx={{ mt: 3, mb: 2, color: "white", backgroundColor: "primary.main", height: "3.5rem" }}>
            Weiter
          </Button>
          <LoginError msg={errorMsg} visible={showLoginError} />
        </Box>
      </Grid>
      <Grid item xs={1} md={4}>
        <Box></Box>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
