import React from "react"
import { Grid, Typography, Paper, Box } from "@mui/material"

const MainFeaturedPost = ({ post, hidden, landing, align }) => {
  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "#91cb76",
        color: "#fff",
        mb: 4,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: align ? align : "center",
        backgroundImage: `url(${post.image})`,
      }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: landing ? "" : "rgba(0,0,0,.3)",
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
              cursor: "default"
            }}>
            <Typography sx={{visibility: hidden ? "hidden" : "visible"}} component="h1" variant="h3" color="inherit" gutterBottom>
              {post.title}
            </Typography>
            <Typography sx={{visibility: hidden ? "hidden" : "visible"}} variant="h5" color="inherit" paragraph>
              {post.description}
            </Typography>
            <Typography variant="subtitle1" sx={{visibility: hidden ? "hidden" : "visible"}}>
              {post.linkText}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MainFeaturedPost
