import React, { useState, useEffect } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"

const CookieFormular = () => {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    const ls = localStorage.getItem("cookieConsent")
    const agreed = ls || false
    setOpen(!agreed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAccept = (val) => {
    if (val) {
      localStorage.setItem("cookieConsent", true)
    }
  }

  return (
    <Dialog
      role="main"
      open={open}
      onClose={() => {
        setOpen(false)
        handleAccept("false")
      }}>
      <DialogTitle>Diese Web-Applikation verwendet Cookies</DialogTitle>
      <DialogContent
        sx={{
          py: "10vh",
          width: "40vw",
        }}>
        Cookies sind für die Bookay-Web-Applikation notwending. Sie speichern lediglich funktionalitätsrelevante Daten wie Nutzer-IDs. Außerdem
        erhöhen nicht notwendige, aber empfohlene Cookies die Performance der Applikation und die Qualität der Nutzererfahrung.
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          sx={{ mx: 2, fontSize: "smaller" }}
          variant="outlined"
          onClick={() => {
            setOpen(false)
            handleAccept(true)
          }}>
          Nur notwendige zulassen
        </Button>
        <Button
          fullWidth
          sx={{ mx: 2, fontSize: "smaller" }}
          variant="contained"
          onClick={() => {
            setOpen(false)
            handleAccept(true)
          }}>
          Alle Cookies zulassen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CookieFormular
