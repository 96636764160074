import React, { useState } from "react"
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import LicencePaper from "./LicencePaper"
import { licencesList, modules } from "../Licences/Licences"

const LizenzAuswahl = ({ lizenzenInfo, setLizenzenInfo, cleanInitialLI }) => {
  const { lizenzen: initLicence, module: initModule } = lizenzenInfo
  const result = Object.keys(initLicence).map((key) => [key, initLicence[key]])
  const initial = result.filter((r) => r[1])

  const [chosenLicence, setChosenLicence] = useState(initial[0][0])
  const [chosenModules, setChosenModules] = useState(initModule)

  const handleChooseLicence = (licence) => {
    const newData = JSON.parse(JSON.stringify(cleanInitialLI))
    // alle werte sind jetzt false
    newData.lizenzen[licence] = true
    setLizenzenInfo(newData)
    setChosenLicence(licence)
  }

  const handleChooseModules = (module) => {
    const newData = JSON.parse(JSON.stringify(lizenzenInfo))
    newData.module[module] = !newData.module[module]
    setLizenzenInfo(newData)
    setChosenModules(newData.module)
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Lizenzen und Module wählen
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "33%" }}>Lizenzen</Typography>
          <Typography color="text.secondary">Ausgewählt: V {chosenLicence}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {licencesList.map((licence, index) => (
              <LicencePaper key={index} licence={licence} handleSelect={handleChooseLicence} isChosen={chosenLicence === licence.id} />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "33%" }}>Module</Typography>
          <Typography color="text.secondary">
            Ausgewählt: (
            {
              Object.keys(chosenModules)
                .map((key) => chosenModules[key])
                .filter((m) => m).length
            }
            )
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {modules.map((module, index) => (
              <LicencePaper key={index} licence={module} handleSelect={handleChooseModules} isChosen={chosenModules[module.id]} />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default LizenzAuswahl
