import React, { useState } from "react"
import { DialogTitle, DialogContent, DialogActions, Dialog, Button } from "@mui/material"

const EntryWarning = ({ warning, setShouldForce, wantedAction }) => {
  const [open, setOpen] = useState(true)

  let msg = ""
  let status = ""
  let aktion = ""
  let aktion2 = ""
  let confirmEnabled = false

  switch (warning.type) {
    case "duplicate":
      aktion = warning.info.Aktion ? "kaufen" : "verkaufen"
      switch (warning.info.Status) {
        case "suche":
          status = "Es ist noch aktiv."
          break
        case "matched":
          status = "Es wurde schon ein Partner gefunden."
          break
        default:
          status = "Fehler"
          break
      }
      msg = "Du möchtest dieses Buch bereits " + aktion
      break

    case "fertig":
      aktion = warning.info.Aktion ? "gekauft" : "verkauft"
      msg = "Du hast dieses Buch bereits " + aktion
      aktion2 = wantedAction ? "kaufen" : "verkaufen"
      status = "Willst Du es trotzdem " + aktion2 + "?"
      confirmEnabled = true
      break
    default:
      msg = "Fehler"
      status = "Fehler"
      break
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}>
      <DialogTitle>{msg}</DialogTitle>
      <DialogContent
        sx={{
          py: "10vh",
          width: "100%",
        }}>
        {status}
      </DialogContent>
      {confirmEnabled && (
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false)
              setShouldForce(true)
            }}>
            Trotzdem {aktion2}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default EntryWarning
