export const alleBundeslaender = [
    "BadenWuerttenberg",
    "Bayern",
    "Berlin",
    "Brandenburg",
    "Bremen",
    "Hamburg",
    "Hessen",
    "MecklenburgVorpommern",
    "Niedersachsen",
    "NordrheinWestfalen",
    "RheinlandPfalz",
    "Saarland",
    "Sachsen",
    "SachsenAnhalt",
    "SchleswigHolstein",
    "Thueringen"
]
  
export const alleAktuellenBundeslaender = [
    "Berlin",
    "Niedersachsen",
    "SachsenAnhalt",
    "Saarland",
    "RheinlandPfalz",
]