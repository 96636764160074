import React from "react"
import { useState } from "react"
import LicenceContext from "./LicenceContext"

const initialLicence = () => {
  const u = localStorage.getItem("licence")
  return u ? JSON.parse(u) : null
}

const LicenceProvider = (props) => {
  const [licenceData, setLicenceData] = useState(initialLicence())

  const setLicenceDataExternal = (data) => {
    localStorage.setItem("licence", JSON.stringify(data))
    setLicenceData(data)
  }

  return <LicenceContext.Provider value={{ licenceData, setLicenceDataExternal }}>{props.children}</LicenceContext.Provider>
}

export default LicenceProvider
