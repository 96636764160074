import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#98e39c",
    },
    secondary: {
      main: "#206e24",
    },
    bmprimary: {
      main: "#7395fa",
    },
    bmsecondary: {
      main: "#142969",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FFFFFF",
    },
  },
})

export default theme
