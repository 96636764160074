import React from "react"
import { Typography, Grid, Divider } from "@mui/material"

const HistoryCalculation = ({ calc, isMobile }) => {
  const { kaufen, verkaufen } = calc
  return (
    <Grid container>
      {isMobile ? (
        <Grid item xs={12}>
          <Typography variant="caption">
            Ergebnisse können abweichen, wenn von der Schule kein Originalpreis angegeben wurde. Bücherpakete werden nicht berücksichtigt.
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item container xs={isMobile ? 12 : 5.9} sx={{ mt: isMobile ? 2 : 0 }}>
        {kaufen.anzahl ? (
          <>
            <Grid item xs={isMobile ? 10 : 6}>
              <Typography ml={1}>Gekaufte Bücher</Typography>
            </Grid>
            <Grid item xs={isMobile ? 2 : 6}>
              <Typography align="right">{kaufen.anzahl}</Typography>
            </Grid>
          </>
        ) : null}
        {kaufen.summe ? (
          <>
            <Grid item xs={isMobile ? 10 : 6}>
              <Typography ml={1}>Gekauft für insgesamt</Typography>
            </Grid>
            <Grid item xs={isMobile ? 2 : 6}>
              <Typography align="right">{kaufen.summe} €</Typography>
            </Grid>
          </>
        ) : null}
        {kaufen.originalSumme ? (
          <>
            <Grid item xs={isMobile ? 10 : 6}>
              <Typography ml={1}>Originalsumme</Typography>
            </Grid>
            <Grid item xs={isMobile ? 2 : 6}>
              <Typography align="right">{kaufen.originalSumme} €</Typography>
            </Grid>
            {kaufen.gespart ? (
              <>
                <Grid item xs={isMobile ? 10 : 6}>
                  <Typography ml={1}>Gespart durch Bookay</Typography>
                </Grid>
                <Grid item xs={isMobile ? 2 : 6}>
                  <Typography align="right">{kaufen.gespart} €</Typography>
                </Grid>
              </>
            ) : null}
          </>
        ) : null}
      </Grid>
      <Grid item xs={isMobile ? 12 : 0.2}>
        <Divider sx={{ my: isMobile ? 2 : 0 }} orientation={isMobile ? "horizontal" : "vertical"} />
      </Grid>
      <Grid item container xs={isMobile ? 12 : 5.9}>
        {verkaufen.anzahl ? (
          <>
            <Grid item xs={isMobile ? 10 : 6}>
              <Typography ml={1}>Verkaufte Bücher</Typography>
            </Grid>
            <Grid item xs={isMobile ? 2 : 6}>
              <Typography align="right">{verkaufen.anzahl}</Typography>
            </Grid>
          </>
        ) : null}
        {verkaufen.summe ? (
          <>
            <Grid item xs={isMobile ? 10 : 6}>
              <Typography ml={1}>Verkauft für insgesamt</Typography>
            </Grid>
            <Grid item xs={isMobile ? 2 : 6}>
              <Typography align="right">{verkaufen.summe} €</Typography>
            </Grid>
          </>
        ) : null}
        {verkaufen.originalSumme ? (
          <>
            <Grid item xs={isMobile ? 10 : 6}>
              <Typography ml={1}>Originalsumme</Typography>
            </Grid>
            <Grid item xs={isMobile ? 2 : 6}>
              <Typography align="right">{verkaufen.originalSumme} €</Typography>
            </Grid>
          </>
        ) : null}
        {verkaufen.wiedergewonnen && verkaufen.wiedergewonnen > 0 ? (
          <>
            <Grid item xs={isMobile ? 10 : 6}>
              <Typography ml={1}>Wiedergewonnen durch Bookay</Typography>
            </Grid>
            <Grid item xs={isMobile ? 2 : 6}>
              <Typography align="right">{verkaufen.wiedergewonnen} €</Typography>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default HistoryCalculation
