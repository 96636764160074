import React from "react"
import { Grid, Typography, Paper } from "@mui/material"

import MainFeaturedPost from "../MainFeaturedPost"
import Main from "../Main"
import Sidebar from "../Sidebar"

import aboutimg from "../img/about.jpg"

const mainFeaturedPost = {
  title: "Das Bookay Projekt",
  description: "Von Schülern - Für Schüler",
  image: aboutimg,
  imageText: "Students",
  linkText: "Angefangen als Unterrichtsprojekt bis zum Schüler-Startup",
}

const posts = [
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      // backgroundImage: `url(${image})`,
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Das Problem & die Lösung
    </Typography>
    <Typography paragraph>
      Der Großteil der Schulen verwendet immer noch ein System, wobei die Schüler ihre Schulbücher selbst kaufen müssen. Dabei bleiben die Bücher
      jedes Jahr die selben!
    </Typography>
    <Typography paragraph>
      Bis zu 700 Euro geben Eltern pro Kind und Jahr (!) für Bücher aus. Nach Gebrauch versinken sie dann meistens im Bücherregal auf ewig. Manche
      versuchen sie im Internet zu verkaufen, aber jede Schule verwendet immer bestimmte Auflagen und Versionen eines Buches...
    </Typography>
    <Typography paragraph>
      Warum also nicht ein eigenes System anwenden, welches einen geschlossenen Kreislauf an Büchern an der Schule herstellt?
    </Typography>
    <Typography paragraph sx={{ fontWeight: "bold" }}>
      Genau das tut Bookay: eine einheitliche Plattform, die einen Bücherkreislauf an Ihrer Schule implementiert.
    </Typography>
    <Typography paragraph>
      Über die Bookay App bieten Schüler ihre Bücher zum Verkauf an und finden Partner, denen Sie die Bücher für das nächste Jahr abkaufen. Der Preis
      für ein Buch wird dabei von den Schülern selbst festgelegt.
    </Typography>
  </Paper>,
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      // backgroundImage: `url(${image})`,
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Die entstehenden Vorteile
    </Typography>
    <Typography paragraph>Somit sparen und gewinnen:</Typography>
    <Typography paragraph>• Eltern Geld, weil sich die Kosten der Bücher durch den Verkauf der Bücher des Vorjahres decken,</Typography>
    <Typography paragraph>• Schüler Zeit, weil die Bücher für das nächste Jahr schon in der Schule sind,</Typography>
    <Typography paragraph>• Fördervereine Mittel, weil es keine eigenen Bücherbasare mehr geben muss,</Typography>
    <Typography paragraph>• Schulkoordinatoren Personal, weil die Schüler sich um ihre Bücher selbst kümmern</Typography>
    <Typography paragraph></Typography>
  </Paper>,
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      // backgroundImage: `url(${image})`,
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Wer steht hinter Bookay?
    </Typography>
    <Typography paragraph>
      Bookay war ursprünglich eine von Dmitrij Tjumenzew entwickelte Softwareprojekt-Idee im Rahmen des Grundkurses Informatik am
      Hans-und-Hilde-Coppi-Gymnasium Berlin.
    </Typography>
    <Typography paragraph>
      Mehr aus Neugier als Strategie wurde die App als Test am Coppi-Gymnasium im Jahr 2021 zum ersten Mal verwendet und half bereits 100 Schülern in
      diesem Schuljahr beim Kauf und Verkauf ihrer Schulbücher. Die dadurch eingesparte Summe entsprach ca. 4.000 €.
    </Typography>
    <Typography paragraph>
      Als das kleine Team aus 3 Schülern feststellte, dass sich eine sehr große Anzahl an Berliner Schulen exakt das gleiche Büchersystem verwendet
      wie unsere, begannen wir unsere App an anderen Schulen zu präsentieren.
    </Typography>
    <Typography paragraph>
      Bookay befindet sich noch in der Entwicklungsphase, und aktuell sind wir auf dem Weg, in mehr Bundesländern präsent zu sein.
    </Typography>
  </Paper>,
]

const About = ({ setCurrentPage }) => {
  return (
    <>
      <MainFeaturedPost post={mainFeaturedPost} align="right"/>
      <Grid container spacing={5} sx={{ mt: 3 }}>
        <Main title="Warum lohnt sich Bookay?" posts={posts} />
        <Sidebar setCurrentPage={setCurrentPage} />
      </Grid>
    </>
  )
}

export default About
