import React from "react"
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography, GlobalStyles, Container, Divider, Tooltip } from "@mui/material"

import StarIcon from "@mui/icons-material/StarBorder"

export const licencesList = [
  {
    title: "Progressiv",
    id: "Progressiv",
    subheader: "Für kleinere Schulen",
    price: "10",
    description: ["∞ Schülerzugänge", "1 Jahr Laufzeit"],
    shortDescription: ["∞ Schülerzugänge", " | 1 Jahr Laufzeit", " | 1 € / Schüler"],
    buttonText: "Loslegen",
    buttonVariant: "outlined",
  },
  {
    title: "Basis",
    id: "Basis",
    subheader: "Für mittlere Schulen",
    price: "500",
    description: ["300 Schülerzugänge", "4 Wochen Laufzeit"],
    shortDescription: ["300 Schülerzugänge", " | 4 Wochen Laufzeit"],
    buttonText: "Loslegen",
    buttonVariant: "contained",
  },
  {
    title: "Komplett",
    id: "Full",
    subheader: "Für große Schulen",
    price: "1.100",
    description: ["∞ Schülerzugänge", "1 Jahr Laufzeit"],
    shortDescription: ["∞ Schülerzugänge", " | 1 Jahr Laufzeit"],
    buttonText: "Loslegen",
    buttonVariant: "outlined",
  },
]

export const modules = [
  {
    title: "Fast Track",
    id: "Fast-Track",
    subheader: "Wir erledigen alles",
    price: "100",
    description: [
      "Kein manuelles Eintragen mehr! Wir tragen alle Bücher selbst in Ihr Profil ein. Sie benötigen nur ein Excel- oder PDF-Dokument ihrer Listen.",
    ],
    shortDescription: ["Schneller vorankommen"],
    buttonText: "Hinzufügen",
    buttonVariant: "outlined",
  },
  {
    title: "Eigene Fächer",
    id: "Eigene-Faecher",
    subheader: "Schulspezifische Fächer",
    price: "100",
    description: ["Fügen Sie Ihre eigenen spezifischen Fächer hinzu. Hier sind alle Fächer aufgelistet, die in jeder Lizenz enthalten sind: ..."],
    alleFaecher: ["Deutsch, Englisch, Französisch, Mathematik, Geschichte, Geografie, Biologie, Physik, Chemie, Kunst, Musik"],
    shortDescription: ["Eigene Fächer"],
    buttonText: "Hinzufügen",
    buttonVariant: "contained",
  },
  {
    title: "Personalisiert",
    id: "Personalisiertes-Modul",
    subheader: "Nichts passendes gefunden?",
    price: "",
    description: [
      "Wir können schnell und einfach individuelle Extra-Funktionen für Ihre Schule konzipieren, bspw. bestimmte Klassenstufen hinzufügen etc.",
    ],
    shortDescription: ["Individuelle Lösungen"],
    buttonText: "Hinzufügen",
    buttonVariant: "contained",
  },
]

const Licences = ({ setCurrentPage }) => {
  return (
    <>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }} />
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 2, pb: 6 }}>
        <Typography variant="h4" align="center" color="text.primary" gutterBottom>
          Lizenzen
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Lizenzen sind Grundpakete, die sich nach der Größe Ihrer Schule richten. Welche Lizenz passt Ihrer Schule am besten?
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {licencesList.map((tier) => (
            <Grid item key={tier.title} xs={12} sm={tier.title === "Full" ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Basis" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: "primary.main",
                    // backgroundColor: (theme) => theme.palette.grey[200],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}>
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                      sx={{ visibility: tier.id === "Personalisierte-Lizenz" ? "hidden" : "visible" }}>
                      {tier.price}€
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      sx={{ display: tier.id !== "Full" && tier.id !== "Progressiv" ? "none" : "inherit" }}>
                      {tier.id === "Progressiv" ? "/ Schüler" : "/ Jahr"}
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant="contained" onClick={() => setCurrentPage("checkout")}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ my: 3 }} />
      </Container>
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 2, pb: 6 }}>
        <Typography variant="h4" align="center" color="text.primary" gutterBottom>
          Module
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" gutterBottom>
          Module sind einzelne Extra-Funktionen, welche an manchen Schulen benötigt werden oder weitere Tools freischalten.
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {modules.map((module) => (
            <Grid item key={module.title} xs={12} sm={module.title === "Full" ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={module.title}
                  subheader={module.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={module.title === "Eigene Fächer" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[400],
                  }}
                />
                <CardContent
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[200],
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}>
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                      sx={{ visibility: module.title === "Personalisiert" ? "hidden" : "visible" }}>
                      {module.price}€
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ visibility: module.title === "Personalisiert" ? "hidden" : "visible" }}>
                      / Jahr
                    </Typography>
                  </Box>
                  <ul>
                    {module.description.map((line) =>
                      module.id === "Eigene-Faecher" ? (
                        <Tooltip placement="bottom" arrow title={module?.alleFaecher}>
                          <Typography component="li" variant="subtitle1" align="center" key={line}>
                            {line}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      )
                    )}
                  </ul>
                </CardContent>
                <CardActions
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[200],
                  }}>
                  <Button fullWidth variant="contained" onClick={() => setCurrentPage("checkout")}>
                    {module.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default Licences
