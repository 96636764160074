import React from "react"
import { Button, IconButton, Dialog, DialogTitle, Slide, DialogContent, Grid, Paper, Typography, Divider } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import SellIcon from "@mui/icons-material/Sell"
import Shop2Icon from "@mui/icons-material/Shop2"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import CloseIcon from "@mui/icons-material/Close"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const BookDialog = ({ handleClose, open, book, buy, sell, isMobile }) => {
  const { Titel, ISBN, Fach, Klasse, Originalpreis, Verlag, Kommentar, Is_Bundle } = book
  return (
    <Dialog TransitionComponent={Transition} onClose={handleClose} open={open}>
      <DialogTitle>
        {Is_Bundle ? `Bücherpaket Klasse ${Klasse}` : Titel}
        <IconButton sx={{ position: "absolute", right: "1vw", top: "1vh" }} onClick={handleClose}>
          <CloseIcon color="secondary" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: "auto",
        }}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {isMobile ? (
                <></>
              ) : (
                <TableRow>
                  <TableCell align="left">Titel</TableCell>
                  <TableCell align="right">{Is_Bundle ? `Bücherpaket Klasse ${Klasse}` : Titel}</TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell align="left">Klasse</TableCell>
                <TableCell align="right">{Klasse}</TableCell>
              </TableRow>
              {!Is_Bundle && (
                <>
                  <TableRow>
                    <TableCell align="left">Fach</TableCell>
                    <TableCell align="right">{Fach}</TableCell>
                  </TableRow>
                  {ISBN ? (
                    <TableRow>
                      <TableCell align="left">ISBN</TableCell>
                      <TableCell align="right">{ISBN}</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                  {Verlag ? (
                    <TableRow>
                      <TableCell align="left">Verlag</TableCell>
                      <TableCell align="right">{Verlag}</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                  {Originalpreis ? (
                    <TableRow>
                      <TableCell align="left">Originalpreis</TableCell>
                      <TableCell align="right">{Originalpreis} €</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <TableRow>
                <TableCell align="left">Endültiger Preis</TableCell>
                <TableCell align="right">Nach Absprache mit Partner</TableCell>
              </TableRow>
              {Kommentar || Is_Bundle ? (
                <TableRow>
                  <TableCell align="left">Information</TableCell>
                  <TableCell align="right">{Is_Bundle ? `Alle Bücher der Klasse ${Klasse} zusammen.` : Kommentar}</TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container sx={{ width: "100%", mt: 2, height: "auto" }} spacing={2}>
          <Grid container item xs>
            <Grid item xs={12}>
              {Klasse >= 13 || Klasse === 0 ? (
                <Typography align="center">Ich möchte {Is_Bundle ? "alle Bücher" : "dieses Buch"} kaufen</Typography>
              ) : (
                <Typography align="center">
                  Ich komme in die {Klasse}. Klasse und möchte {Is_Bundle ? "alle Bücher" : "dieses Buch"} kaufen
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <KeyboardDoubleArrowDownIcon sx={{ align: "center", width: "100%" }} />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={buy} fullWidth variant="contained" startIcon={<Shop2Icon />}>
                Kaufen
              </Button>
            </Grid>
          </Grid>
          {isMobile ? (
            <></>
          ) : (
            <Grid item xs={0.2}>
              <Divider orientation="vertical" />
            </Grid>
          )}

          <Grid container item xs>
            <Grid item xs={12}>
              {Klasse >= 13 || Klasse === 0 ? (
                <Typography align="center">Ich möchte {Is_Bundle ? "alle Bücher" : "dieses Buch"} verkaufen</Typography>
              ) : (
                <Typography align="center">
                  Ich komme in die {Klasse + 1}. Klasse und möchte {Is_Bundle ? "alle Bücher" : "dieses Buch"} verkaufen
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <KeyboardDoubleArrowDownIcon sx={{ align: "center", width: "100%" }} />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={sell} fullWidth variant="contained" endIcon={<SellIcon />}>
                Verkaufen
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "3vh" }} />

        <Typography sx={{ mt: "1vh", color: "gray" }} variant="caption">
          Es wird lediglich ein Partner gesucht und ein engültiger Kauf / Verkauf ist nicht garantiert.
          <br />
          Preis nach Absprache mit dem Partner.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default BookDialog
