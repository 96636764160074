import React, { useState, useEffect, useContext } from "react"
import {
  Typography,
  Button,
  ButtonGroup,
  Paper,
  Tooltip,
  TableCell,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Box,
  Table,
  IconButton,
  TextField,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  Modal,
  Grid,
  Snackbar,
  Alert
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { getFaecher, addSubject } from "../../fetch/faecher"
import { buchBearbeiten, buchHinzufuegen, buchLoeschen } from "../../fetch/bookmanagement"
import { getBooksByClass } from "../../fetch/trading"
import UserContext from "../../context/UserContext"
import LicenceContext from "../../context/LicenceContext"

const CustomPaper = (props) => <Paper elevation={0} {...props} />

const emptyBook = {
  id: "",
  Titel: "",
  ISBN: "",
  Originalpreis: "",
  Fach: "",
  Klasse: "",
  Kommentar: "",
  Verlag: "",
}

const Bookmanagement = () => {
  const { userData } = useContext(UserContext)
  const { licenceData } = useContext(LicenceContext)
  const schoolID = userData?.SchulID
  const { module } = licenceData
  const addSubjectsLicence = module.includes(7) // LizenzID 7: Fächer hinzufügen

  const [faecher, setFaecher] = useState([])
  const [currentClass, setCurrentClass] = useState(7)
  const [books, setBooks] = useState([])
  const [createMode, setCreateMode] = useState(false)
  const [validBooks, setValidBooks] = useState([])
  const [currentBook, setCurrentBook] = useState(emptyBook)
  const [newTitle, setNewTitle] = useState("")
  const [newISBN, setNewISBN] = useState("")
  const [newPrice, setNewPrice] = useState("")
  const [newComment, setNewComment] = useState("")
  const [newVerlag, setNewVerlag] = useState("")
  const [newFach, setNewFach] = useState("")
  const [showCreateSubjectDialog, setShowCreateSubjectDialog] = useState(false)
  const [newSubject, setNewSubject] = useState("") // for creating subjects
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [open, setOpen] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")

  useEffect(() => {
    getFaecher(setFaecher)
  }, [])

  useEffect(() => {
    getBooksByClass(currentClass, schoolID, setBooks)
  }, [currentClass, schoolID])

  useEffect(() => {
    const filtered = books.filter((b) => !b.Is_Bundle)
    setValidBooks(filtered)
  }, [books])

  const resetEntry = () => {
    setCreateMode(false)
    setCurrentBook(emptyBook)
    setNewTitle("")
    setNewISBN("")
    setNewPrice("")
    setNewComment("")
    setNewVerlag("")
    setNewFach("")
  }

  const handleClick = (book) => {
    setCurrentBook(book)
    const { Titel, ISBN, Originalpreis, Fach, Kommentar, Verlag } = book
    const FachID = faecher.filter((f) => f.Name === Fach)
    setNewTitle(Titel)
    setNewISBN(ISBN)
    setNewPrice(Originalpreis)
    setNewComment(Kommentar)
    setNewVerlag(Verlag)
    setNewFach(FachID[0]?.FID)
  }

  const handleAddBook = async (event) => {
    event.preventDefault()
    try {
      const addBook = await buchHinzufuegen({
        titel: newTitle,
        SchulID: schoolID,
        isbn: newISBN,
        fach: newFach,
        klasse: currentClass,
        ogpreis: newPrice,
        kommentar: newComment,
        verlag: newVerlag,
      })
      if (addBook.error) {
        console.log(addBook.message)
      } else {
        setOpen(true)
        setSnackbarMsg("Buch gespeichert")
        getBooksByClass(currentClass, schoolID, setBooks)
      }
    } catch (e) {
      console.log(e.message)
    }
    resetEntry()
  }

  const handleSaveEditedBook = async () => {
    const { id } = currentBook
    try {
      const newBookData = {
        bid: id,
        titel: newTitle,
        SchulID: schoolID,
        isbn: newISBN,
        fach: newFach,
        klasse: currentClass,
        ogpreis: newPrice,
        kommentar: newComment,
        verlag: newVerlag,
      }
      const editBook = await buchBearbeiten(newBookData)
      if (editBook.error) {
        console.log(editBook.message)
      } else {
        setOpen(true)
        setSnackbarMsg("Änderungen gespeichert")
        getBooksByClass(currentClass, schoolID, setBooks)
      }
    } catch (e) {
      console.log(e.message)
    }
    resetEntry()
  }

  const handleConfirmDeleteBook = async () => {
    const { id } = currentBook
    try {
      const deleteBook = await buchLoeschen(id)
      if (deleteBook.error) {
        console.log(deleteBook.message)
      } else {
        setOpen(true)
        setSnackbarMsg("Buch gelöscht")
        getBooksByClass(currentClass, schoolID, setBooks)
      }
    } catch (e) {
      console.log(e.message)
    }
    resetEntry()
    setShowDeleteConfirm(false)
  }

  const handleCreateSubject = async (event) => {
    event.preventDefault()
    try {
      const newSubj = await addSubject({ name: newSubject, schoolID })
      if (newSubj.error) {
        console.log(newSubj.error)
      } else {
        setOpen(true)
        setSnackbarMsg("Fach erstellt")
        getFaecher(setFaecher)
      }
    } catch (e) {
      console.log(e.message)
    }
    setShowCreateSubjectDialog(false)
  }

  const rows = Object.keys(validBooks).map((key) => validBooks[key])

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "1vw",
          left: "12vw",
          borderRadius: "38px",
          backgroundColor: "white",
          height: "calc(100vh - 2vw)",
          width: "87vw",
          p: "1vw",
          overflow: "auto",
          msOverflowStyle: "-ms-autohiding-scrollbar" /* IE and Edge */, // ????
          scrollbarWidth: "none" /* Firefox */,
        }}>
        <Typography variant="h5" align="left" sx={{ ml: "1vw", mt: "1vw", color: "bmsecondary.main" }}>
          {currentClass ? `Bücherliste Klasse ${currentClass}` : "Extrabücher"}
        </Typography>
        <Paper elevation={6} sx={{ width: "calc(100% - 2vw)", mx: "1vw", mt: "5vh", borderRadius: "20px" }}>
          <ButtonGroup
            variant="outlined"
            sx={{ width: "100%", height: "5vh", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", overflow: "hidden" }}>
            {[7, 8, 9, 10, 11, 12, 13, 0].map((c) => (
              <Button
                onClick={() => setCurrentClass(c)}
                fullWidth
                key={c}
                sx={{
                  backgroundColor: c !== currentClass ? "bmprimary.main" : "white",
                  color: c === currentClass ? "bmsecondary.main" : "bmsecondary.main",
                  borderColor: "bmprimary.main",
                  "&:hover": { borderColor: "bmprimary.main" },
                  "&:last-child": { borderTopRightRadius: "20px" },
                  "&:first-child": { borderTopLeftRadius: "20px" },
                }}>
                {c ? c : "Extra"}
              </Button>
            ))}
          </ButtonGroup>

          <TableContainer
            sx={{ borderBottomRightRadius: "20px", borderBottomLeftRadius: "20px", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            component={CustomPaper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "25%" }}>Titel</TableCell>
                  <TableCell sx={{ width: "10%" }} align="right">
                    Fach
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="right">
                    ISBN
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="right">
                    Verlag
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="right">
                    Originalpreis
                  </TableCell>
                  <TableCell sx={{ width: "25%" }} align="right">
                    Information
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <>
                    {row?.id === currentBook?.id ? (
                      <>
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
                          <TableCell component="th" scope="row">
                            <TextField
                              autoFocus
                              required
                              id="title"
                              name="title"
                              placeholder="Titel *"
                              fullWidth
                              variant="standard"
                              onChange={(e) => setNewTitle(e.target.value)}
                              value={newTitle}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <FormControl variant="standard" sx={{ minWidth: 120, width: "100%", m: 0 }}>
                              <Select
                                required
                                value={newFach}
                                displayEmpty
                                id="fach"
                                name="fach"
                                variant="standard"
                                renderValue={(selected) => {
                                  if (!selected || selected.length === 0) {
                                    return <Typography color="grey.400">Fach *</Typography>
                                  } else {
                                    const temp = faecher.filter((f) => f.FID === newFach)
                                    return temp[0]?.Name
                                  }
                                }}
                                onChange={(e) => setNewFach(e.target.value)}>
                                <MenuItem disabled value="">
                                  <em>Fach</em>
                                </MenuItem>
                                {faecher.map((f, index) => (
                                  <MenuItem key={index} value={f?.FID}>
                                    {f?.Name}
                                  </MenuItem>
                                ))}
                                <MenuItem
                                  onClick={() => setShowCreateSubjectDialog(true)}
                                  value={0}
                                  sx={{ color: !!addSubjectsLicence ? "inherit" : "grey.400" }}
                                  disabled={!addSubjectsLicence}>
                                  Neues Fach anlegen
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              fullWidth
                              required
                              id="isbn"
                              placeholder="ISBN *"
                              name="isbn"
                              variant="standard"
                              onChange={(event) => {
                                let rawVal = event.target.value
                                let a = rawVal.toString()
                                if (a === " " || a.length === 0 || (a.length === 1 && isNaN(a))) {
                                  setNewISBN("")
                                } else {
                                  setNewISBN(parseInt(a))
                                }
                              }}
                              value={newISBN}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              fullWidth
                              required
                              id="verlag"
                              placeholder="Verlag"
                              name="verlag"
                              variant="standard"
                              onChange={(event) => setNewVerlag(event.target.value)}
                              value={newVerlag}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Input
                              fullWidth
                              placeholder="Org. Preis"
                              id="price"
                              name="price"
                              endAdornment={<InputAdornment position="end">€</InputAdornment>}
                              variant="standard"
                              onChange={(event) => {
                                let rawVal = event.target.value
                                let a = rawVal.toString()
                                let c = a.replace(",", ".")
                                let b = c.replace(/\s/g, "")
                                if (b === " " || b.length === 0 || isNaN(b) || (b.match(".") || []).length > 1) {
                                  setNewPrice(0)
                                } else {
                                  setNewPrice(b)
                                }
                              }}
                              value={newPrice || ""}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              fullWidth
                              placeholder="Information"
                              required
                              id="comment"
                              name="comment"
                              variant="standard"
                              onChange={(event) => setNewComment(event.target.value)}
                              value={newComment}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row" sx={{ float: "right", py: 1 }}>
                            <Button
                              variant="contained"
                              sx={{
                                fontSize: "smaller",
                                width: "30%",
                                mr: "3%",
                                backgroundColor: "bmprimary.main",
                                color: "white",
                                "&:hover": { backgroundColor: "bmsecondary.main" },
                              }}
                              onClick={handleSaveEditedBook}
                              disabled={!newTitle || !newISBN || !newFach || newTitle.length === 0 || newISBN.length === 0 || newFach.length === 0}>
                              Speichern
                            </Button>
                            <Button
                              variant="contained"
                              color="warning"
                              sx={{ fontSize: "smaller", width: "30%", mx: "1.5%" }}
                              onClick={() => setShowDeleteConfirm(true)}>
                              Löschen
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                fontSize: "smaller",
                                width: "30%",
                                ml: "3%",
                                backgroundColor: "bmprimary.main",
                                color: "white",
                                "&:hover": { backgroundColor: "bmsecondary.main" },
                              }}
                              onClick={resetEntry}>
                              Abbrechen
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow
                        onClick={() => handleClick(row)}
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
                        <TableCell component="th" scope="row">
                          {row.Titel}
                        </TableCell>
                        <TableCell align="right">{row.Fach}</TableCell>
                        <TableCell align="right">{row.ISBN}</TableCell>
                        <TableCell align="right">{row.Verlag}</TableCell>
                        <TableCell align="right">{row.Originalpreis ? `${row.Originalpreis} €` : ""}</TableCell>
                        <TableCell align="right">{row.Kommentar}</TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
                {!createMode ? (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
                    <TableCell component="th" scope="row" sx={{ py: 1 }}>
                      <Tooltip title="Neues Buch anlegen" placement="right" arrow>
                        <IconButton onClick={() => setCreateMode(true)}>
                          <AddCircleIcon fontSize="smaller" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
                      <TableCell component="th" scope="row">
                        <TextField
                          autoFocus
                          required
                          id="title"
                          name="title"
                          placeholder="Titel *"
                          fullWidth
                          variant="standard"
                          onChange={(e) => setNewTitle(e.target.value)}
                          value={newTitle}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <FormControl variant="standard" sx={{ minWidth: 120, width: "100%", m: 0 }}>
                          <Select
                            required
                            value={newFach}
                            displayEmpty
                            id="fach"
                            name="fach"
                            variant="standard"
                            renderValue={(selected) => {
                              if (!selected || selected.length === 0) {
                                return <Typography color="grey.400">Fach *</Typography>
                              } else {
                                const temp = faecher.filter((f) => f.FID === selected)
                                return temp[0].Name
                              }
                            }}
                            onChange={(e) => setNewFach(e.target.value)}>
                            <MenuItem disabled value="">
                              <em>Fach</em>
                            </MenuItem>
                            {faecher.map((f, index) => (
                              <MenuItem key={index} value={f?.FID}>
                                {f?.Name}
                              </MenuItem>
                            ))}
                            <MenuItem
                              onClick={() => setShowCreateSubjectDialog(true)}
                              value={0}
                              sx={{ color: !!addSubjectsLicence ? "inherit" : "grey.400" }}
                              disabled={!addSubjectsLicence}>
                              Neues Fach anlegen
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          fullWidth
                          required
                          id="isbn"
                          placeholder="ISBN *"
                          name="isbn"
                          variant="standard"
                          onChange={(event) => {
                            let rawVal = event.target.value
                            let a = rawVal.toString()
                            if (a === " " || a.length === 0 || (a.length === 1 && isNaN(a))) {
                              setNewISBN("")
                            } else {
                              setNewISBN(parseInt(a))
                            }
                          }}
                          value={newISBN}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          fullWidth
                          required
                          id="verlag"
                          placeholder="Verlag"
                          name="verlag"
                          variant="standard"
                          onChange={(event) => setNewVerlag(event.target.value)}
                          value={newVerlag}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Input
                          fullWidth
                          placeholder="Org. Preis"
                          id="price"
                          name="price"
                          endAdornment={<InputAdornment position="end">€</InputAdornment>}
                          variant="standard"
                          onChange={(event) => {
                            let rawVal = event.target.value
                            let a = rawVal.toString()
                            let c = a.replace(",", ".")
                            let b = c.replace(/\s/g, "")
                            if (b === " " || b.length === 0 || isNaN(b) || (b.match(".") || []).length > 1) {
                              setNewPrice(0)
                            } else {
                              setNewPrice(b)
                            }
                          }}
                          value={newPrice || ""}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          fullWidth
                          placeholder="Information"
                          required
                          id="comment"
                          name="comment"
                          variant="standard"
                          onChange={(event) => setNewComment(event.target.value)}
                          value={newComment}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row" sx={{ float: "right", py: 1 }}>
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: "smaller",
                            width: "45%",
                            mr: "5%",
                            backgroundColor: "bmprimary.main",
                            color: "white",
                            "&:hover": { backgroundColor: "bmsecondary.main" },
                          }}
                          onClick={handleAddBook}
                          disabled={newTitle.length === 0 || newISBN.length === 0 || newFach.length === 0}>
                          Speichern
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: "smaller",
                            width: "45%",
                            ml: "5%",
                            backgroundColor: "bmprimary.main",
                            color: "white",
                            "&:hover": { backgroundColor: "bmsecondary.main" },
                          }}
                          onClick={resetEntry}>
                          Abbrechen
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Modal open={showCreateSubjectDialog} onClose={() => setShowCreateSubjectDialog(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              width: 400,
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
            }}>
            <Typography variant="h6" component="h2">
              Neues Fach anlegen
            </Typography>
            <TextField
              variant="outlined"
              label="Name"
              required
              name="newSubjectName"
              id="newSubjectName"
              fullWidth
              value={newSubject}
              onChange={(e) => setNewSubject(e.target.value)}
              sx={{ my: "5vh" }}
            />
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <Button variant="outlined" fullWidth onClick={handleCreateSubject} disabled={!newSubject.length}>
                  Hinzufügen
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" color="warning" fullWidth onClick={() => setShowCreateSubjectDialog(false)}>
                  Abbrechen
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              width: 400,
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
            }}>
            <Typography variant="h6" component="h2">
              Löschen bestätigen
            </Typography>
            <Typography sx={{ mt: 2, mb: 3 }}>
              <b>{newTitle}</b> löschen? <br />
              Dieser Vorgang kann nicht rückgängig gemacht werden.
            </Typography>
            <Button
              variant="contained"
              color="warning"
              sx={{ width: "40%", color: "white", mx: "5%" }}
              onClick={handleConfirmDeleteBook}
              startIcon={<DeleteIcon />}>
              Löschen
            </Button>
            <Button variant="contained" sx={{ width: "40%", color: "white", mx: "5%" }} onClick={() => setShowDeleteConfirm(false)}>
              Abbrechen
            </Button>
          </Box>
        </Modal>
      </Box>

      <Snackbar sx={{ color: "bmsecondary.main" }} open={open} autoHideDuration={5000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: "100%" }}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Bookmanagement
