import React from "react"
import { Grid, Typography, Paper } from "@mui/material"

import Main from "../Main"

const posts = [
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Bookay (www.bookay-buecher.de)
    </Typography>
    <Typography>
      <h1>Allgemeine Geschäftsbedingungen</h1>
      <h2>Geltungsbereich</h2>
      Für alle Anfragen von Verbrauchern an Bookay gelten diese Allgemeinen Geschäftsbedingungen (AGB).
      <h2>Verarbeitung</h2>
      <b>
        Die Darstellung der Produkte (Lizenzen und Module) im Online-Shop stellt kein rechtlich bindendes Angebot, sondern lediglich eine Information
        dar.
      </b>
      <br />
      Durch Anklicken des Buttons [Unverbindlich anfragen] geben Sie eine unverbindliche Anfrage der auf der Zusammenfassung aufgelisteten Produkte
      ab. Ein Kaufvertrag kommt dadurch nicht zustande, auch nachdem wir Ihre Anfrage durch eine Anfragebestätigung per E-Mail nach dem Erhalt Ihrer
      Anfrage annehmen.
    </Typography>
  </Paper>,
]

const AGB = () => {
  return (
    <>
      <Grid container spacing={5} sx={{ mt: 1 }}>
        <Main title="Allgemeine Geschäftsbedingungen" posts={posts} fullSize />
      </Grid>
    </>
  )
}

export default AGB
